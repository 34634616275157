var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("cupertino-alert-dialog", {
    attrs: { title: "上書き投稿の確認", close: _vm.onClickOutSide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "px-1 py-1" }, [
              _vm._v(" " + _vm._s(_vm.content) + " "),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex flex-row flex-center flex-spacearound" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full border-right-1 flex flex-center pointer py-1 hover left-bottom-radius-10",
                    on: { click: _vm.onCancelClick },
                  },
                  [_c("span", [_vm._v("キャンセル")])]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full flex flex-center pointer py-1 hover right-bottom-radius-10",
                    on: { click: _vm.onUpdateClick },
                  },
                  [
                    _c("span", { staticClass: "color-blue bold" }, [
                      _vm._v("上書きする"),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }