var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _vm.isLinking
        ? _c(
            "div",
            { staticClass: "flex flex-center" },
            [
              _c("vue-loading", {
                attrs: {
                  type: "spiningDubbles",
                  color: "#efad4c",
                  size: { width: "25px", height: "25px" },
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "dialog flex flex-column line-height-initial" },
            [
              _c("div", { staticClass: "header flex flex-row space-between" }, [
                _c("span", { staticClass: "bold" }, [
                  _vm._v("Nolaノベルで作品を公開する"),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "pointer",
                    on: { click: _vm.onNegativeClick },
                  },
                  [_vm._v("×ウィンドウを閉じる")]
                ),
              ]),
              _c("div", { staticClass: "content flex flex-row flex-1" }, [
                _c("div", { staticClass: "flex flex-column flex-6 scroll" }, [
                  _vm.isPost
                    ? _c("h2", { staticClass: "px-1" }, [
                        _vm._v("Nolaノベルのどの作品に投稿しますか？"),
                      ])
                    : _c("h2", { staticClass: "px-1" }, [
                        _vm._v("Nolaノベルのどの作品と連携しますか？"),
                      ]),
                  _vm.isPost
                    ? _c("div", { staticClass: "flex flex-column" }, [
                        _vm._m(0),
                        _c("div", {
                          staticClass: "w-full",
                          style: { height: "1px", background: "#D6D6D6" },
                        }),
                        _c("div", { staticClass: "novel-list-row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-row px-1 gap-1 align-items-center",
                              style: {
                                paddingTop: "1.0rem",
                                paddingBottom: "1.0rem",
                              },
                            },
                            [
                              _vm._m(1),
                              _c(
                                "div",
                                {
                                  staticClass: "btn new pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.navigateToNolaNovelEditPage()
                                    },
                                  },
                                },
                                [_vm._v("選択")]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._m(2),
                  _c(
                    "div",
                    { staticClass: "flex-1 novel-list" },
                    [
                      _c("div", {
                        staticClass: "w-full",
                        style: { height: "1px", background: "#D6D6D6" },
                      }),
                      _vm.isLoading
                        ? _c(
                            "div",
                            { staticClass: "flex flex-center py-1" },
                            [
                              _c("vue-loading", {
                                attrs: {
                                  type: "spiningDubbles",
                                  color: "#efad4c",
                                  size: { width: "25px", height: "25px" },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoading && _vm.novels.length === 0
                        ? _c(
                            "div",
                            { staticClass: "h-full flex flex-center" },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass: "text-center",
                                  style: { lineHeight: "1.5rem" },
                                },
                                [
                                  _vm._v(" Nolaノベルに作品がありません。"),
                                  _c("br"),
                                  _vm._v(
                                    " Nolaで書いた作品を投稿してみましょう！！ "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._l(_vm.novels, function (novel) {
                            return _c(
                              "div",
                              { key: novel.id, staticClass: "novel-list-row" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex flex-row px-1 gap-1 align-items-center",
                                    style: {
                                      paddingTop: "1.0rem",
                                      paddingBottom: "1.0rem",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-center" },
                                      [
                                        _c("image-view", {
                                          style: {
                                            height: "70px",
                                            width: "50px",
                                          },
                                          attrs: {
                                            src: _vm.getImagePath(novel),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-1 flex align-items-center",
                                        style: { minWidth: "0px" },
                                      },
                                      [
                                        _c(
                                          "h3",
                                          { staticClass: "line-3 w-full m-0" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(novel.title) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "btn select pointer",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onNovelSelect(novel)
                                          },
                                        },
                                      },
                                      [_vm._v("選択")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-column flex-4 border-left-1 border-left-light-gray",
                    style: { background: "#D1E1F4" },
                  },
                  [
                    _vm._m(3),
                    _c(
                      "div",
                      {
                        staticClass: "w-full flex flex-column",
                        style: { background: "white" },
                      },
                      [
                        _c(
                          "h2",
                          {
                            style: {
                              paddingLeft: "1.4rem",
                              paddingRight: "1.4rem",
                            },
                          },
                          [
                            _vm._v(
                              " Nolaノベルは、作家と編集者がつながる小説投稿サイトです "
                            ),
                          ]
                        ),
                        _c("div", {
                          staticClass: "light-gray m-0",
                          style: {
                            height: "0.3rem",
                            marginLeft: "1.3rem",
                            marginRight: "1.3rem",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "pt-1 bold line-height-18",
                            style: {
                              paddingLeft: "1.5rem",
                              paddingRight: "1.5rem",
                            },
                          },
                          [
                            _vm._v(
                              " Nolaノベルは、様々な企業の編集部が探している作品像を知り、編集者に向けて作品投稿できる小説投稿サイトです。 "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "pb-1 bold line-height-18",
                            style: {
                              paddingLeft: "1.5rem",
                              paddingRight: "1.5rem",
                            },
                          },
                          [
                            _vm._v(
                              " 自分に合うレーベルを見つけたり、商業デビューに近づくきっかけとしてぜひご利用ください。 "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "px-1 py-1 light-gray radius-8 bold mb-2",
                            style: {
                              marginLeft: "1.3rem",
                              marginRight: "1.3rem",
                            },
                          },
                          [
                            _vm._v(
                              " Nola内のデータが許諾なく公開されるようなことはございませんので、ご安心いただけますと幸いです。 "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "light-gray px-1 py-1" }, [
      _c("span", { staticClass: "gray" }, [_vm._v("新規の作品投稿")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-1 flex align-items-center" }, [
      _c("h3", { staticClass: "line-3 w-full m-0" }, [
        _vm._v("新規の作品として投稿する"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex flex-column" }, [
      _c("div", { staticClass: "light-gray px-1 py-1" }, [
        _c("span", { staticClass: "gray" }, [
          _vm._v("Nolaノベルに作成済みの作品一覧"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-1 flex pt-1" }, [
      _c("div", { staticClass: "align-self-center w-full h-full guide-image" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }