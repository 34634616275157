var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "theme-editor" },
      [
        _c("h2", { staticClass: "h2" }, [_vm._v("この作品のテーマ")]),
        _c(_setup.ThemeTextField, {
          attrs: {
            label: "◎ 執筆する作品のテーマを記入しましょう。",
            maxlength: 1000,
          },
          model: {
            value: _setup.theme["theme"],
            callback: function ($$v) {
              _vm.$set(_setup.theme, "theme", $$v)
            },
            expression: "theme['theme']",
          },
        }),
        _c("h2", { staticClass: "h2" }, [_vm._v("この作品の終着点")]),
        _c(_setup.ThemeTextField, {
          attrs: {
            label: "◎ 作品のゴールを明確にしましょう。",
            maxlength: 1000,
          },
          model: {
            value: _setup.theme["goal"],
            callback: function ($$v) {
              _vm.$set(_setup.theme, "goal", $$v)
            },
            expression: "theme['goal']",
          },
        }),
        _c("h2", { staticClass: "h2" }, [_vm._v("執筆予定文字数")]),
        _c(
          _setup.ThemeTextField,
          { attrs: { label: "◎ 作品の完成時の予定文字数を入力しましょう。" } },
          [
            _c("div", { staticClass: "input-wrapper" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _setup.theme["word-length"],
                    expression: "theme['word-length']",
                  },
                ],
                staticClass: "word-length",
                attrs: {
                  type: "text",
                  placeholder: "半角数字で入力",
                  maxlength: "18",
                },
                domProps: { value: _setup.theme["word-length"] },
                on: {
                  blur: _setup.formatWordLength,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_setup.theme, "word-length", $event.target.value)
                  },
                },
              }),
              _c("span", [_vm._v("文字予定")]),
            ]),
          ]
        ),
        _vm._m(0),
        _c("h2", { staticClass: "h2" }, [_vm._v("この作品のターゲット層")]),
        _c(_setup.ThemeTextField, {
          attrs: {
            label: "◎ どんな人に読んで欲しいのか明確にしましょう。",
            "sub-label": "① 年齢層・性別",
            maxlength: 1000,
          },
          model: {
            value: _setup.theme["target-segment"],
            callback: function ($$v) {
              _vm.$set(_setup.theme, "target-segment", $$v)
            },
            expression: "theme['target-segment']",
          },
        }),
        _c(_setup.ThemeTextField, {
          attrs: { label: "", "sub-label": "② 趣味・嗜好", maxlength: 1000 },
          model: {
            value: _setup.theme["target-preference"],
            callback: function ($$v) {
              _vm.$set(_setup.theme, "target-preference", $$v)
            },
            expression: "theme['target-preference']",
          },
        }),
        _c(_setup.ThemeTextField, {
          attrs: {
            label: "",
            "sub-label": "③ その人が他に読みそうな作品",
            maxlength: 1000,
          },
          model: {
            value: _setup.theme["target-prefer-novels"],
            callback: function ($$v) {
              _vm.$set(_setup.theme, "target-prefer-novels", $$v)
            },
            expression: "theme['target-prefer-novels']",
          },
        }),
        _c(
          "div",
          [
            _c(
              _setup.ButtonDefault,
              {
                class: { active: _setup.changed },
                attrs: { disabled: !_setup.changed },
                on: { click: _setup.submit },
              },
              [_vm._v(" 変更内容を保存する ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "annotation" }, [
      _vm._v(" ※ 文字数の参考： "),
      _c("br"),
      _vm._v(
        " 単行本小説：20万〜30万字 / 短編小説：5000〜2万字 / ショートショート：〜5000字 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }