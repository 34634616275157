
import Vue, { PropType } from "vue";
import PlotItemCard from "@/components/atoms/PlotItemCard.vue";
import PlotItemDetail from "@/components/atoms/PlotItemDetail.vue";
import InputText from "@/components/atoms/InputText.vue";
import SelectBoxV2 from "@/components/atoms/SelectBoxV2.vue";
import isMobile from "ismobilejs";
import { Dialog } from "@/lib/utils";
import BreakingChangeConfirm, { BreakingChangeConfirmProps } from "@/components/ui/dialogs/BreakingChangeConfirm.vue";
import { plotLayouts, PlotLayoutSelectBoxItem, plotModes, PlotModeSelectBoxItem } from "@/lib/plot";

import draggable from "vuedraggable";
import {
  Layout,
  Mode,
  Plot,
  PlotGroups,
  Plots,
  SubPlot,
  CreateSubPlotPayload,
  UpdatePlotPayload,
  UpdateSubPlotPayload,
  DisplayFormat,
} from "@/lib/models/plot";
import { v4 as uuidv4 } from "uuid";
import ChevronDownIcon from "icons/ChevronDown.vue";
import PlusCircleOutlineIcon from "icons/PlusCircleOutline.vue";
import ContentDuplicateIcon from "icons/ContentDuplicate.vue";
import DotsHorizontalCircleIcon from "icons/DotsHorizontalCircle.vue";
import DragHorizontalVariantIcon from "icons/DragHorizontalVariant.vue";
import clone from "lodash/cloneDeep";
import { handleShortcutKeys, TriggerKey } from "@/lib/utils/keyboardShortcuts";

const ClickOutside = require("vue-click-outside");

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: { ClickOutside },
  components: {
    InputText,
    PlotItemCard,
    PlotItemDetail,
    draggable,
    SelectBoxV2,
    ChevronDownIcon,
    PlusCircleOutlineIcon,
    ContentDuplicateIcon,
    DotsHorizontalCircleIcon,
    DragHorizontalVariantIcon,
  },
  props: {
    novelId: String,
    plotId: String,
    plots: Object as PropType<Plot | SubPlot>,
  },
  data() {
    const { plotId } = this;
    const { plotGroups } = this.plots;

    return {
      plotGroups,
      plotName: plotId ? (this.plots as SubPlot).name : null,
      maxDescriptionLength: 2500,
      changed: false,
      changingPlotName: false,
      displayMenu: -1,
      plotLayouts,
      plotModes,
      selectedLayout: plotLayouts.filter((i) => i.id === this.plots.layout)[0],
      groupDragging: false,
      unbindShortcutKeys: null,
    };
  },
  created() {
    // 保存ボタンのショートカットキーの有効化と解除関数の取得
    this.unbindShortcutKeys = handleShortcutKeys(
      [
        { trigger: TriggerKey.Meta, keys: ["s"], callback: this.save },
        { trigger: TriggerKey.Ctrl, keys: ["s"], callback: this.save },
      ],
      false
    );
  },
  beforeDestroy() {
    // コンポーネントが破棄される前に保存ボタンのショートカットキーを解除
    if (this.unbindShortcutKeys) this.unbindShortcutKeys();
  },
  computed: {
    mode() {
      const { plots } = this;
      return plots.mode || Mode.kishoutenketsu;
    },
    modeName() {
      const { mode } = this;
      return plotModes.find((i) => i.id === mode)!.name;
    },
    isCustom() {
      const { mode } = this;
      return mode === Mode.custom;
    },
    isSP() {
      return isMobile().any;
    },
    isDetail() {
      if (isMobile().any) {
        return true;
      }
      const { selectedLayout } = this;
      return selectedLayout.id === Layout.detail;
    },
  },
  methods: {
    isInitial(plot) {
      const extendedPlot = plot as ExtensionPlot;
      return extendedPlot.isInitial !== undefined ? extendedPlot.isInitial : false;
    },
    setChanged(b) {
      this.changed = b;
      this.setModified(b);
    },
    input() {
      this.setChanged(true);
    },
    async inputItem(plot, plots) {
      const { generateInitialPlot } = this;
      const { isInitial } = plot;

      if (isInitial) {
        const updatedPlot = { ...plot };
        delete updatedPlot.isInitial;
        plots.splice(plots.length - 1, 1, updatedPlot);
        plots.push(generateInitialPlot());
      }

      this.input();
    },
    deleteItem(plot, index) {
      this.plotGroups[index].plots = this.plotGroups[index].plots.filter((x) => x.key !== plot.key);

      this.input();
    },
    onGroupDragStart() {
      this.groupDragging = true;
    },
    onGroupDragEnd() {
      this.groupDragging = false;
      this.setChanged(true);
    },
    onDragEnd() {
      this.setChanged(true);
    },
    addPlotGroup(index) {
      const { $notify, setChanged } = this;

      const plotGroup = {
        name: "",
        description: "",
        plots: [],
      };
      this.plotGroups.splice(index + 1, 0, plotGroup);

      $notify({
        title: "プロットグループが追加されました",
        type: "success",
      });
      setChanged(true);
    },
    clickDisplayMenu(index) {
      if (this.displayMenu === index) {
        this.displayMenu = -1;
      } else {
        this.displayMenu = index;
      }
    },
    // TODO outside clickがちゃんと動いていない💦
    onClickOutside() {
      // this.displayMenu = -1;
    },
    linkPlot(data, plot, index, j) {
      // 新規作成の場合
      if (data.plotId === "new") {
        const { novelId, $router } = this;
        const mode = data.mode.id;
        const layout = Layout.simple;
        const displayFormat = DisplayFormat.HORIZONTAL;

        let plotGroups: PlotGroups[] = [];

        switch (mode) {
          default:
          case Mode.kishoutenketsu:
            plotGroups = [
              { name: "起", description: "", plots: [] },
              { name: "承", description: "", plots: [] },
              { name: "転", description: "", plots: [] },
              { name: "結", description: "", plots: [] },
            ];
            break;
          case Mode.johakyu:
            plotGroups = [
              { name: "序", description: "", plots: [] },
              { name: "破", description: "", plots: [] },
              { name: "急", description: "", plots: [] },
            ];
            break;
          case Mode.custom:
            plotGroups = [
              { name: "第１章", description: "", plots: [] },
              { name: "第２章", description: "", plots: [] },
              { name: "第３章", description: "", plots: [] },
              { name: "第４章", description: "", plots: [] },
            ];
            break;
        }

        const createSubPlotPayload: CreateSubPlotPayload = {
          novelId,
          name: data.name,
          plotGroups,
          mode,
          layout,
          displayFormat,
          callback: async (plotId: string) => {
            const plotGroup = this.plotGroups[index];
            plotGroup.plots[j].linkedPlotKey = plotId;
            this.$set(this.plotGroups, index, plotGroup);
            // 移動するか確認ダイアログを表示する
            const confirmDialog = new Dialog(BreakingChangeConfirm);
            const data: BreakingChangeConfirmProps = {
              title: "作成した子プロットに移動しますか？",
              content: "子プロットに移動して編集作業を進める場合は、現在作業中のプロットを保存して移動します。",
              change: "移動する",
            };
            const result = await confirmDialog.show(data);

            if (!result) {
              return;
            }
            await this.save(true);
            $router.push({ name: "plotEdit", params: { novelId, plotId } });
          },
        };
        this.$store.dispatch("plotModule/createSubPlot", createSubPlotPayload);
      } else {
        // 既存と紐づける場合
        const plotGroup = this.plotGroups[index];
        plotGroup.plots[j].linkedPlotKey = data.plotId;
        this.$set(this.plotGroups, index, plotGroup);
      }
      this.setChanged(true);
    },
    cancelLink(plot, index, j) {
      const plotGroup = this.plotGroups[index];
      plotGroup.plots[j].linkedPlotKey = "";
      this.$set(this.plotGroups, index, plotGroup);
      this.setChanged(true);
    },
    async deletePlotGroup(index) {
      this.displayMenu = -1;
      const confirmDialog = new Dialog(BreakingChangeConfirm);
      const data: BreakingChangeConfirmProps = {
        title: "このプロットグループを削除しますか？",
        content:
          "削除するとプロットグループに紐づくプロットアイテムや子プロットとの連携など、プロットグループ内の全てのデータが削除されます。",
        change: "削除する",
      };
      const result = await confirmDialog.show(data);
      if (!result) {
        return;
      }
      const { $notify, setChanged } = this;

      this.plotGroups = this.plotGroups.filter((_, i) => i !== index);

      $notify({
        title: "プロットグループを削除しました",
        type: "error",
      });
      setChanged(true);
    },
    generateInitialPlot() {
      const initialPlot: ExtensionPlot = {
        key: uuidv4(),
        text: "",
        isInitial: true,
        linkedPlotKey: "",
      };
      return initialPlot;
    },
    modeClick() {
      const { $router, novelId, plotId, plots, mode } = this;

      if (plotId) {
        $router.push({
          name: "plotChange",
          params: { novelId, plotId },
          query: { mode: mode || "kishoutenketsu" },
        });
      } else {
        $router.push({
          name: "plotChange",
          params: { novelId },
          query: { mode: plots.mode || "kishoutenketsu" },
        });
      }
    },
    onClickContentCopy(index) {
      const { $notify, setChanged } = this;

      const newPlotGroup = clone(this.plotGroups[index]);
      // プロットのkeyはコピーせずに新規生成する
      newPlotGroup.plots = newPlotGroup.plots.map((plot) => {
        const newPlot = clone(plot);
        newPlot.key = uuidv4();
        return newPlot;
      });
      this.plotGroups.splice(index + 1, 0, newPlotGroup);

      $notify({
        title: "プロットグループが複製されました",
        type: "success",
      });
      setChanged(true);
    },
    changePlotNameStatus() {
      if (this.plotId) {
        this.changingPlotName = !this.changingPlotName;
      }
    },
    resetPlotNameStatus() {
      this.changingPlotName = false;
    },
    async save(isTransition) {
      if (!this.changed) return;

      const { $store, $router, $notify, novelId, plotId, mode, selectedLayout, plotName, setChanged } = this;
      let { plotGroups } = this;
      let { displayFormat } = this.plots;

      // remove initial plot
      plotGroups = plotGroups.map((plotGroup) => {
        const { name, description, plots } = plotGroup;
        return {
          name,
          description,
          plots: (plots as ExtensionPlot[]).filter((plot) => !plot.isInitial),
        };
      });

      // レイアウトを変更した場合はdisplayFormatも更新する
      if (selectedLayout.id !== this.plots.layout) {
        displayFormat = selectedLayout.id === Layout.detail ? DisplayFormat.VERTICAL : DisplayFormat.HORIZONTAL;
      }

      if (plotId && plotName) {
        const updateSubPlotPayload: UpdateSubPlotPayload = {
          novelId,
          plotId,
          name: plotName,
          plotGroups,
          mode,
          layout: selectedLayout.id,
          displayFormat,
        };
        $store.dispatch("plotModule/updateSubPlot", updateSubPlotPayload);
      } else {
        const updatePlotPayload: UpdatePlotPayload = {
          novelId,
          plotGroups,
          layout: selectedLayout.id,
          displayFormat,
        };
        $store.dispatch("plotModule/updatePlot", updatePlotPayload);
      }

      $notify({
        title: "保存しました",
        type: "success",
      });
      setChanged(false);

      if (!isTransition) {
        if (plotId) {
          $router.push({ name: "plot", params: { novelId, plotId } });
        } else {
          $router.push({ name: "plot", params: { novelId } });
        }
      }
    },
  },
  watch: {
    plotGroups: {
      handler() {
        const { plotGroups, generateInitialPlot } = this;

        plotGroups.forEach((plotGroup) => {
          // check if plotGroup has initial plot
          const hasInitialPlot = (plotGroup.plots as ExtensionPlot[]).some((plot) => plot.isInitial);
          if (!hasInitialPlot) {
            plotGroup.plots.push(generateInitialPlot());
          }

          // check if initial plot is last in the list
          const initialPlotIndex = (plotGroup.plots as ExtensionPlot[]).findIndex((plot) => plot.isInitial);
          if (plotGroup.plots.length - 1 !== initialPlotIndex) {
            const initialPlot = plotGroup.plots[initialPlotIndex];
            plotGroup.plots.splice(
              0,
              plotGroup.plots.length,
              ...plotGroup.plots.filter((_, index) => index !== initialPlotIndex)
            );
            plotGroup.plots.push(initialPlot);
          }
        });
      },
      deep: true,
      immediate: true,
    },
    selectedLayout() {
      this.setChanged(true);
    },
  },
});

interface Props {
  novelId: string;
  plotId?: string;
  plots: Plot | SubPlot;
}

interface Data {
  plotGroups: PlotGroups[];
  plotName: string | null;
  maxDescriptionLength: number;
  changed: boolean;
  changingPlotName: boolean;
  displayMenu: number;
  plotLayouts: PlotLayoutSelectBoxItem[];
  plotModes: PlotModeSelectBoxItem[];
  selectedLayout: PlotLayoutSelectBoxItem;
  groupDragging: boolean;
  unbindShortcutKeys: (() => void) | null;
}

interface Computed {
  mode: Mode;
  modeName: String;
  isCustom: boolean;
  isDetail: boolean;
  isSP: boolean;
}

interface Methods {
  isInitial(plot: Plots): boolean;
  setChanged(b: boolean): void;
  input(): void;
  inputItem(plot: ExtensionPlot, plots: ExtensionPlot[]): Promise<void>;
  deleteItem(plot: ExtensionPlot, index: number): void;
  onGroupDragStart(): void;
  onGroupDragEnd(): void;
  onDragEnd(): void;
  addPlotGroup(index: number): void;
  clickDisplayMenu(index: number): void;
  onClickOutside(): void;
  linkPlot(
    data: { plotId: string; name: string; mode: PlotModeSelectBoxItem },
    plot: ExtensionPlot,
    index: number,
    j: number
  ): void;
  cancelLink(plot: ExtensionPlot, index: number, j: number): void;
  deletePlotGroup(index: number): void;
  generateInitialPlot(): ExtensionPlot;
  save(isTransition: boolean): void;
  modeClick(): void;
  onClickContentCopy(index: number): void;
  changePlotNameStatus(): void;
  resetPlotNameStatus(): void;
}

type ExtensionPlot = Plots & {
  isInitial?: boolean;
};
