var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "plot-list" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "dragscroll",
            rawName: "v-dragscroll",
            value: !_vm.isEditing,
            expression: "!isEditing",
          },
        ],
        staticClass: "plot-item-container",
        class: { grabbable: !_vm.isEditing },
      },
      [
        _c(
          "div",
          {
            staticClass: "plot-item default",
            class: { active: _vm.isActive() },
            on: {
              click: function ($event) {
                return _vm.onClickPlot()
              },
            },
          },
          [_vm._v("全体プロット")]
        ),
        _c(
          "draggable",
          {
            staticClass: "draggable",
            attrs: {
              animation: 300,
              ghostClass: "ghost",
              disabled: !_vm.isEditing,
            },
            on: { end: _vm.onDragEnd },
            model: {
              value: _vm.dataSubPlots,
              callback: function ($$v) {
                _vm.dataSubPlots = $$v
              },
              expression: "dataSubPlots",
            },
          },
          _vm._l(_vm.dataSubPlots, function (item) {
            return _c(
              "div",
              {
                key: item.plotId,
                staticClass: "plot-item",
                class: {
                  active: _vm.isActive(item.plotId),
                  grabbable: _vm.isEditing,
                },
                on: {
                  click: function ($event) {
                    return _vm.onClickPlot(item.plotId)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            )
          }),
          0
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "button-container" },
      [
        _vm.isEditing
          ? [
              _c("checkbox-marked-circle-icon", {
                staticClass: "icon done",
                on: {
                  click: function ($event) {
                    return _vm.onClickEdit(false)
                  },
                },
              }),
            ]
          : [
              _c("plus-circle-icon", {
                staticClass: "icon add",
                on: { click: _vm.onClickAdd },
              }),
              _c("playlist-edit-icon", {
                staticClass: "icon edit",
                on: {
                  click: function ($event) {
                    return _vm.onClickEdit(true)
                  },
                },
              }),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }