var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "item" }, [
    _c(
      "div",
      { staticClass: "plot-item-content" },
      [
        !_vm.isInitial
          ? [
              _c("div", { staticClass: "item-handle handle-disp" }),
              _c(
                "div",
                {
                  staticClass: "delete-button handle-disp",
                  on: { click: _vm.deleteItem },
                },
                [_vm._v("×")]
              ),
            ]
          : _vm._e(),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputValue,
              expression: "inputValue",
            },
          ],
          staticClass: "plot-card",
          attrs: {
            placeholder: "プロットの内容をここに記入",
            maxlength: "2500",
          },
          domProps: { value: _vm.inputValue },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.inputValue = $event.target.value
            },
          },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }