var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.hidden,
          expression: "!hidden",
        },
      ],
      staticClass: "sb-tab",
    },
    _vm._l(_vm.items, function (item) {
      return _c(
        "div",
        { key: item.to, class: { item: true, active: item.to === _vm.active } },
        [
          _c(
            "router-link",
            {
              staticClass: "link",
              attrs: { tag: "button", to: { name: item.to } },
            },
            [_vm._v(_vm._s(item.description))]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }