var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "root" }, [
    _c("div", { staticClass: "manuscript-editor" }, [
      _c("div", { staticClass: "bottom sp" }, [
        _c(
          "button",
          { staticClass: "back sp", on: { click: _vm.handleBackAction } },
          [_vm._v("＜")]
        ),
        _c(
          "div",
          {
            staticClass: "sp save",
            attrs: { "data-disabled": !_vm.changed },
            on: { click: _vm.save },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/img/icon/icon-save.png") },
            }),
            _vm._v("保存 "),
          ]
        ),
        _c("div", { staticClass: "edit-manu" }, [
          _c("img", {
            attrs: { src: require("@/assets/img/icon/icon-edit-menu.png") },
            on: { click: _vm.showMenu },
          }),
        ]),
        _vm.content !== null
          ? _c("div", { staticClass: "text-counter" }, [
              _vm._v(_vm._s(_vm.contentLength) + "字"),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "manuscript-container" },
        [
          _c("manuscript-header", {
            attrs: {
              title: _vm.title,
              novelId: _vm.novelId,
              manuscriptKey: _vm.manuscriptKey,
              content: _vm.content,
              changed: _vm.changed,
            },
            on: {
              changeTitle: _vm.onChangeTitle,
              compositionStart: _vm.onStartComposition,
              compositionEnd: _vm.onEndComposition,
              post: function ($event) {
                return _vm.onExportButtonClick(true)
              },
              save: _vm.save,
              commentIconClicked: _vm.handleCommentIconClick,
            },
          }),
          _c(
            "div",
            {
              staticClass: "editor-container",
              class: { isShowPreview: _vm.isShowPreview },
            },
            [
              _c("editor", {
                attrs: { title: _vm.title, content: _vm.content },
                on: {
                  changeTitle: _vm.onChangeTitle,
                  changeContent: _vm.onChangeContent,
                  compositionStart: _vm.onStartComposition,
                  compositionEnd: _vm.onEndComposition,
                },
              }),
              _vm.isShowPreview
                ? _c("manuscript-preview", {
                    staticClass: "preview pc",
                    attrs: {
                      novelId: _vm.novelId,
                      manuscriptList: _vm.manuscriptList,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom pc" },
            [
              _c(
                "div",
                { staticClass: "controller-container gap-1 ml-1" },
                [
                  _c("undo-icon", {
                    attrs: {
                      fillColor: _vm.canUndo
                        ? _vm.activeColor
                        : _vm.defaultColor,
                    },
                    on: { click: _vm.undo },
                  }),
                  _c("redo-icon", {
                    attrs: {
                      fillColor: _vm.canRedo
                        ? _vm.activeColor
                        : _vm.defaultColor,
                    },
                    on: { click: _vm.redo },
                  }),
                  _c("hr", { staticClass: "vertical-hr" }),
                  _c("div", { staticClass: "editor-controller" }, [
                    _c("div", { staticClass: "insert-symbol" }, [
                      _c(
                        "div",
                        {
                          staticClass: "icon-box",
                          on: { click: _vm.brackets },
                        },
                        [_c("div", [_vm._v("「」")])]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "icon-box",
                          on: { click: _vm.ellipsis },
                        },
                        [_c("div", [_vm._v("……")])]
                      ),
                      _c(
                        "div",
                        { staticClass: "icon-box", on: { click: _vm.space } },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/icon/space_bar.svg"),
                              alt: "space bar",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "icon-box", on: { click: _vm.dash } },
                        [_c("div", [_vm._v("――")])]
                      ),
                      _c(
                        "div",
                        { staticClass: "icon-box", on: { click: _vm.ruby } },
                        [_c("div", [_vm._v("ルビ")])]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "icon-box",
                          on: { click: _vm.emphasisMark },
                        },
                        [_c("div", [_vm._v("傍点")])]
                      ),
                    ]),
                  ]),
                  _c("hr", { staticClass: "vertical-hr" }),
                  _c(
                    "button",
                    {
                      staticClass: "button default maulti-editor-link md",
                      on: { click: _vm.goToMultiEditor },
                    },
                    [_vm._v("マルチ執筆モード")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "show-preview",
                      class: { isShowPreview: _vm.isShowPreview },
                      on: { click: _vm.onClickPreview },
                    },
                    [
                      _c("text-vertical-icon", {
                        attrs: { isActive: _vm.isShowPreview },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "setting-container" },
                [
                  _c("dropup-menu", {
                    staticClass: "dropup-menu",
                    attrs: {
                      title: _vm.countTypeName,
                      content: _vm.wordCountInfo,
                      items: _vm.countTypeList,
                      styleObject: { backgroundColor: "#EDEDED" },
                    },
                  }),
                ],
                1
              ),
              _c("proofreading-confirmation-dialog"),
              _c("auto-indent-confirmation-dialog"),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }