
import Vue from "vue";
import CharacterForm from "@/components/organisms/CharacterForm.vue";
import ButtonDefault from "@/components/atoms/ButtonDefault.vue";
import ImageSelector from "@/components/molecules/ImageSelector.vue";
import { submitImage } from "@/lib/image-save";
import { Character, UpdateCharacter } from "@/lib/models";
import { cloneDeep } from "lodash";
import { handleShortcutKeys, TriggerKey } from "@/lib/utils/keyboardShortcuts";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { CharacterForm, ButtonDefault, ImageSelector },
  props: {
    novelId: String,
    characterId: String,
  },
  data() {
    return {
      model: {
        name: "",
        detail: [],
        additionalColumn: [],
      },
      image: null,
      unbindShortcutKeys: null,
    };
  },
  async created() {
    const {
      character: { name, detail, additionalColumn, image },
    } = this;
    this.image = image;
    this.model = { name, detail, additionalColumn };

    // 保存ボタンのショートカットキーの有効化と解除関数の取得
    this.unbindShortcutKeys = handleShortcutKeys([
      { trigger: TriggerKey.Meta, keys: ["s"], callback: this.submit },
      { trigger: TriggerKey.Ctrl, keys: ["s"], callback: this.submit },
    ]);
  },
  beforeDestroy() {
    // コンポーネントが破棄される前に保存ボタンのショートカットキーを解除
    if (this.unbindShortcutKeys) this.unbindShortcutKeys();
  },
  computed: {
    character() {
      return cloneDeep(this.$store.getters["characterModule/character"](this.characterId));
    },
    imagePath() {
      const { novelId, characterId, image } = this;
      if (!image) return null;
      if (image.startsWith("file:")) {
        return `file:novels/${novelId}/characters/${characterId}.jpg`;
      }
      if (image.startsWith("preset:")) {
        return image;
      }
      return null;
    },
  },
  methods: {
    change() {
      this.setModified(true);
    },
    async submit() {
      const { novelId, characterId, model, image } = this;
      const { additionalColumn } = model;
      model.additionalColumn = additionalColumn.filter((item) => item.label || item.content);

      const updateCharacterInput: UpdateCharacter = {
        novelId,
        characterId,
        image,
        ...model,
      };
      await this.$store.dispatch("characterModule/updateCharacter", updateCharacterInput);
      await submitImage(`novels/${novelId}/characters/${characterId}.jpg`);

      this.$notify({
        title: "保存しました",
        type: "success",
      });

      this.setModified(false);

      this.$router.push({
        name: "characters",
        params: { novelId, characterId },
      });
    },
  },
});

interface Props {
  novelId: string;
  characterId: string;
}

interface Data {
  model: {
    name: string;
    detail: any[];
    additionalColumn: any[];
  };
  image: string | null;
  unbindShortcutKeys: (() => void) | null;
}

interface Computed {
  character: Character;
  imagePath: string | null;
}

interface Methods {
  change(): void;
  submit(): Promise<void>;
}
