var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "shared-link-list" }, [
    _c("div", { staticClass: "title-wrapper" }, [
      _c("span", { staticClass: "arrow", on: { click: _vm.back } }, [
        _vm._v("＜"),
      ]),
      _c("span", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.sharedLinkName)),
      ]),
    ]),
    _vm.kind === _vm.SharedLinkKind.MANUSCRIPT
      ? _c(
          "div",
          { staticClass: "list" },
          [
            _vm.manuscriptList.length
              ? _c(
                  "transition-group",
                  { attrs: { name: "tran" } },
                  _vm._l(_vm.manuscriptList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.key,
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.select(item.key)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "item-title ellipsis" }, [
                          _vm._v(
                            " " +
                              _vm._s(item.title || "（タイトル未設定）") +
                              " "
                          ),
                        ]),
                        _vm.isIncludesUnreadComment(item.key)
                          ? _c("dot-icon", { staticClass: "notification" })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "description" }, [
                  _c("div", [_vm._v("共有されている原稿はありません")]),
                ]),
          ],
          1
        )
      : _vm._e(),
    _vm.kind === _vm.SharedLinkKind.PLOT
      ? _c(
          "div",
          { staticClass: "list" },
          [
            _vm.plotList.length
              ? _c(
                  "transition-group",
                  { attrs: { name: "tran" } },
                  _vm._l(_vm.plotList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.plotId,
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.select(item.plotId)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "item-title ellipsis" }, [
                          _vm._v(
                            " " +
                              _vm._s(item.name || "（タイトル未設定）") +
                              " "
                          ),
                        ]),
                        _vm.isIncludesUnreadComment(item.plotId)
                          ? _c("dot-icon", { staticClass: "notification" })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "description" }, [
                  _c("div", [_vm._v("共有されているプロットはありません")]),
                ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }