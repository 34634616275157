var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "manuscript-preview-page" },
    [
      _c("manuscript-preview", {
        staticClass: "preview",
        attrs: { novelId: _vm.novelId, manuscriptList: _vm.manuscriptList },
      }),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "empty" }),
        _c(
          "button",
          { staticClass: "button", on: { click: _vm.onClickClose } },
          [_vm._v("閉じる")]
        ),
        _c(
          "div",
          { staticClass: "setting-container" },
          [
            _c("dropup-menu", {
              staticClass: "dropup-menu",
              attrs: {
                title: _vm.countTypeName,
                content: _vm.wordCountInfo,
                items: _vm.countTypeList,
                styleObject: { backgroundColor: "#EDEDED" },
              },
            }),
            _c("hamburger-menu", {
              staticClass: "hamburger-menu",
              attrs: {
                items: _vm.hamburgerMenuList,
                styleObject: { backgroundColor: "#EDEDED" },
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }