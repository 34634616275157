var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "list-template",
        [
          _c("correlation-group-list", {
            attrs: {
              slot: "side",
              "novel-id": _vm.novelId,
              items: _vm.correlationGroupList,
              selectedCorrelationGroupId: _vm.correlationGroupId,
            },
            on: { fetch: _vm.fetchCorrelationGroupList },
            slot: "side",
          }),
          _vm.correlationGroupId
            ? _c(
                "div",
                {
                  staticClass: "correlation-main-content",
                  attrs: { slot: "main" },
                  slot: "main",
                },
                [
                  _c("correlation-list", {
                    attrs: {
                      "novel-id": _vm.novelId,
                      items: _vm.correlationList,
                      selectedCorrelationGroupId: _vm.correlationGroupId,
                      selectedCorrelationId: _vm.correlationId,
                      characterItems: _vm.characterList,
                      correlationDiagram: _vm.getCorrelationDiagram(),
                    },
                    on: { fetch: _vm.fetchCorrelationList },
                  }),
                  _c("correlation-content", {
                    key: _vm.correlationGroupId,
                    attrs: {
                      "novel-id": _vm.novelId,
                      correlationGroupName: _vm.getCorrelationGroupName(),
                      selectedCorrelationGroupId: _vm.correlationGroupId,
                      items: _vm.correlationList,
                      characterItems: _vm.characterList,
                      correlationDiagram: _vm.getCorrelationDiagram(),
                    },
                    on: { fetch: _vm.fetchCorrelationGroupList },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }