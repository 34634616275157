var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "worldview-form" }, [
    _c(
      "div",
      { staticClass: "form" },
      [
        _c("text-field", {
          attrs: {
            label: "名前 ※必須",
            placeholder: "世界観の名前を入力してください。",
            maxlength: 50,
          },
          on: { input: _vm.change },
          model: {
            value: _vm.name,
            callback: function ($$v) {
              _vm.name = $$v
            },
            expression: "name",
          },
        }),
        _vm._l(_vm.detail, function (item) {
          return [
            _c("text-field", {
              key: item.name,
              attrs: {
                label: item._label,
                placeholder: item._placeholder,
                maxlength: 2000,
                rows: item._rows || 1,
              },
              on: { input: _vm.change },
              model: {
                value: item.value,
                callback: function ($$v) {
                  _vm.$set(item, "value", $$v)
                },
                expression: "item.value",
              },
            }),
          ]
        }),
        _vm._m(0),
        _c(
          "div",
          { staticClass: "additional-column" },
          [
            _vm._l(_vm.additionalColumn, function (item, index) {
              return [
                _c(
                  "div",
                  {
                    key: index,
                    staticClass: "additional-content",
                    attrs: { id: index },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: index != _vm.additionalColumn.length - 1,
                            expression: "index != additionalColumn.length - 1",
                          },
                        ],
                        staticClass: "remove-button",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/icon/remove.png"),
                            width: "18",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.remove(index)
                            },
                          },
                        }),
                      ]
                    ),
                    _c("text-field", {
                      key: `label-${item.name}`,
                      ref: `label-${item.name}`,
                      refInFor: true,
                      attrs: {
                        label: `項目名（30文字以内）`,
                        placeholder: "例：国民性、特産物、人種など",
                        maxlength: 30,
                        disabled: _vm.plan === 0,
                        id: `label-${item.name}`,
                      },
                      on: { input: _vm.change },
                      model: {
                        value: item.label,
                        callback: function ($$v) {
                          _vm.$set(item, "label", $$v)
                        },
                        expression: "item.label",
                      },
                    }),
                    _c("text-field", {
                      key: `content-${item.name}`,
                      ref: `content-${item.name}`,
                      refInFor: true,
                      attrs: {
                        label: `内容`,
                        placeholder: "ここに設定内容を記入してください。",
                        disabled: _vm.plan === 0,
                        rows: 4,
                        id: `content-${item.name}`,
                      },
                      on: { input: _vm.change },
                      model: {
                        value: item.content,
                        callback: function ($$v) {
                          _vm.$set(item, "content", $$v)
                        },
                        expression: "item.content",
                      },
                    }),
                  ],
                  1
                ),
              ]
            }),
            _c("div", { staticClass: "button-center" }, [
              _c(
                "button",
                {
                  staticClass: "button",
                  attrs: { disabled: _vm.plan === 0 },
                  on: {
                    click: function ($event) {
                      return _vm.add()
                    },
                  },
                },
                [_vm._v("＋さらに項目を追加する")]
              ),
            ]),
            _vm.plan === 0 ? _c("premium-information") : _vm._e(),
          ],
          2
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "premium-label" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/icon/premium.png"), width: "18" },
      }),
      _c("span", [_vm._v("好きな名前で項目を追加する")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }