var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("LottieAnimation", {
    ref: "anim",
    attrs: { animationData: _vm.animationData, loop: true, autoPlay: true },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }