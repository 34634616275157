var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "novel-editor-list" }, [
    _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "controller" }, [
        _c(
          "div",
          {
            staticClass: "folder",
            on: {
              click: function ($event) {
                return _vm.backToFolderList()
              },
            },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/img/icon/folder_white.png") },
            }),
          ]
        ),
        _c("div", { staticClass: "title ellipsis" }, [
          _vm._v(
            " " + _vm._s(_vm.folder ? _vm.folder.name : "すべての原稿") + " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "menu" },
          [
            _vm.isEditMode || _vm.isDeleteMode || _vm.isMoveFolderMode
              ? [
                  _c(
                    "span",
                    { staticClass: "edit-end", on: { click: _vm.saveOrder } },
                    [_vm._v("編集終了")]
                  ),
                ]
              : [
                  _c("CogIcon", {
                    staticClass: "flex flex-center",
                    on: {
                      click: function ($event) {
                        _vm.isShowMenu = !_vm.isShowMenu
                      },
                    },
                  }),
                ],
          ],
          2
        ),
      ]),
      _vm.isShowMenu
        ? _c(
            "div",
            {
              staticClass: "hover-menu-mask",
              on: { click: _vm.onClickCloseMenu },
            },
            [
              _c("div", { staticClass: "hover-menu" }, [
                _c(
                  "div",
                  {
                    staticClass: "hover-menu-item",
                    on: { click: _vm.onClickSortManuscript },
                  },
                  [_vm._v("原稿の並び替え")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "hover-menu-item",
                    on: { click: _vm.onClickMoveManuscript },
                  },
                  [_vm._v("フォルダ移動")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "hover-menu-item",
                    on: { click: _vm.onClickImportFile },
                  },
                  [_vm._v("原稿のインポート")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "hover-menu-item",
                    on: { click: _vm.toggleProgressDisplayMode },
                  },
                  [
                    _vm._v(
                      " 進捗表示を" +
                        _vm._s(_vm.isProgressPercentage ? "文字数" : "%") +
                        "に変更する "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "hover-menu-item",
                    on: { click: _vm.onClickDeleteManuscript },
                  },
                  [_vm._v("原稿を削除する")]
                ),
                _c("div", { staticClass: "hover-menu-item close" }, [
                  _vm._v("メニューを閉じる"),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "writing-progress" }, [
        _c("span", [_vm._v("計 " + _vm._s(_vm.totalWordLength) + " 字")]),
        !_vm.folder
          ? _c("span", { staticClass: "remaining-progress" }, [
              _vm._v("(残り " + _vm._s(_vm.remainingProgress) + " %)"),
            ])
          : _vm._e(),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "list" },
      [
        _c("sortable-list", {
          staticClass: "list-container",
          attrs: {
            items: _vm.manuscripts,
            keyAttr: "key",
            disabled: !_vm.isEditMode,
            extra: _vm.isEditMode,
          },
          on: {
            "update:items": function ($event) {
              _vm.manuscripts = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ item, extra }) {
                return [
                  _c("list-item", {
                    attrs: {
                      icon: "none",
                      label: item.title || "(タイトル未設定)",
                      folderName: _vm.folderName(item),
                      isActive: _vm.selected && item.key === _vm.selected.key,
                      showHandle: extra,
                      item: item,
                      customClickFunction: () => _vm.select(item.key),
                      needsDoCustomClickFunction: true,
                      showProgress: true,
                      progress: _vm.unitProgress(item.content),
                      progressWidth: _vm.progressChipMinWidthValue(
                        item.content
                      ),
                      isEditMode: _vm.isEditMode,
                      isManuscriptDeleteMode: _vm.isDeleteMode,
                      isManuscriptMoveFolderMode: _vm.isMoveFolderMode,
                    },
                    on: {
                      clickSetFolder: _vm.setFolder,
                      delete: function ($event) {
                        _vm.deleteManuscript(
                          item.key,
                          item.title || "(タイトル未設定)"
                        )
                      },
                    },
                  }),
                ]
              },
            },
          ]),
        }),
        _vm.isMobileDevice
          ? _c(
              "button",
              {
                staticClass: "sp-create-button",
                on: {
                  click: function ($event) {
                    return _vm.createManuscript()
                  },
                },
              },
              [_vm._v("＋ 新規作成する")]
            )
          : _vm._e(),
      ],
      1
    ),
    !_vm.isMobileDevice
      ? _c("div", { staticClass: "button-container" }, [
          _c(
            "div",
            {
              staticClass: "button",
              class: { active: _vm.activeButton === "create" },
              on: {
                click: function ($event) {
                  _vm.selectButton("create")
                  _vm.createManuscript()
                },
              },
            },
            [
              _c("plus-circle-icon", { staticClass: "material-icon" }),
              _c("span", [_vm._v("新規追加")]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "button",
              class: { active: _vm.activeButton === "preview" },
              on: {
                click: function ($event) {
                  _vm.selectButton("preview")
                  _vm.previewAll()
                },
              },
            },
            [
              _c("view-column-outline-icon", { staticClass: "material-icons" }),
              _c("span", [_vm._v("プレビュー")]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "button",
              class: { active: _vm.activeButton === "ai" },
              on: {
                click: function ($event) {
                  return _vm.selectButton("ai")
                },
              },
            },
            [
              _c("robot-icon", { staticClass: "material-icons" }),
              _c("span", [_vm._v("AI感想")]),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("input", {
      ref: "input",
      staticStyle: { display: "none" },
      attrs: { type: "file", accept: ".txt,.docx", multiple: "" },
      on: {
        change: function ($event) {
          return _vm.onSelectFile()
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }