var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.inProcess
    ? _c(
        "div",
        { staticClass: "full-screen flex flex-center" },
        [
          _c("vue-loading", {
            attrs: {
              type: "spiningDubbles",
              color: "#efad4c",
              size: { width: "25px", height: "25px" },
            },
          }),
        ],
        1
      )
    : _c("cupertino-alert-dialog", {
        attrs: { title: "連携解除の最終確認", close: _vm.onClickOutSide },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "px-1 py-1" }, [
                  _vm._v(
                    " この原稿をNolaノベル上のエピソードと連携解除してよろしいですか？ "
                  ),
                  _c("br"),
                  _vm._v(
                    " なお、連携解除後もNola、Nolaノベルともにデータが削除されることはありません。 "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex flex-row flex-center flex-spacearound" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full border-right-1 flex flex-center pointer py-1 hover left-bottom-radius-10",
                        on: { click: _vm.onCancelClick },
                      },
                      [_c("span", [_vm._v("キャンセル")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full flex flex-center pointer py-1 hover right-bottom-radius-10",
                        on: { click: _vm.onDelinkClick },
                      },
                      [
                        _c("span", { staticClass: "color-red" }, [
                          _vm._v("連携解除する"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }