var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "slide-shared-link-menu",
      class: {
        open: _vm.isOpen,
        plot: _vm.isPlot,
        hide: !_vm.isOpen && _vm.kind === "manuscript",
      },
    },
    [
      _c("div", { staticClass: "tag", on: { click: _vm.onOpenTagClick } }, [
        _vm.isOpen
          ? _c("span", [_c("close-icon")], 1)
          : _c(
              "span",
              [
                _c("comment-icon", {
                  attrs: { isUnread: _vm.hasUnreadComment },
                }),
              ],
              1
            ),
      ]),
      _vm.selectedSharedLinkId && _vm.selectedContentKey
        ? _c("comment-list-on-slide-menu", {
            staticClass: "list",
            attrs: {
              novelId: _vm.novelId,
              sharedLinkId: _vm.selectedSharedLinkId,
              contentKey: _vm.selectedContentKey,
              kind: _vm.kind,
            },
          })
        : _vm.selectedSharedLinkId
        ? _c("shared-link-content-list-on-slide-menu", {
            staticClass: "list",
            attrs: { novelId: _vm.novelId, kind: _vm.kind },
          })
        : _c("shared-link-list-on-slide-menu", {
            staticClass: "list",
            attrs: { novelId: _vm.novelId, kind: _vm.kind },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }