var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "item" },
    [
      _c("div", { staticClass: "circle" }),
      _c("div", { staticClass: "plot-item-content" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputValue,
              expression: "inputValue",
            },
          ],
          staticClass: "plot-card",
          attrs: {
            placeholder: "プロットの内容をここに記入",
            maxlength: "2500",
          },
          domProps: { value: _vm.inputValue },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.inputValue = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _vm.isPC
          ? _c("div", { staticClass: "plot-item-footer" }, [
              (!_vm.child || !_vm.childPlotName) && !_vm.isInitial
                ? _c(
                    "div",
                    {
                      staticClass: "create-child-plot",
                      on: { click: _vm.createChildPlot },
                    },
                    [
                      _c("link-icon", {
                        staticClass: "icon mr-4",
                        attrs: { size: 16 },
                      }),
                      _vm._v("子プロットを作成する "),
                    ],
                    1
                  )
                : !_vm.isInitial
                ? _c("div", { staticClass: "create-child-plot" }, [
                    _c(
                      "div",
                      { staticClass: "link-child" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "plotEdit",
                                params: {
                                  novelId: _vm.novelId,
                                  plotId: _vm.child,
                                },
                              },
                            },
                          },
                          [
                            _c("link-icon", {
                              staticClass: "icon mr-4",
                              attrs: { size: 16 },
                            }),
                            _c("span", [
                              _vm._v(
                                "子プロット：" + _vm._s(_vm.childPlotName)
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "cancel", on: { click: _vm.cancelLink } },
                      [_vm._v("連携を解除する")]
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "menu-parent" },
                [
                  !_vm.isInitial
                    ? _c("dots-horizontal-circle-icon", {
                        staticClass: "icon light-gray",
                        on: { click: _vm.clickMenu },
                      })
                    : _vm._e(),
                  _vm.displayMenu
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.onClickOutside,
                              expression: "onClickOutside",
                            },
                          ],
                          staticClass: "menu",
                          on: { click: _vm.deleteItem },
                        },
                        [_vm._v(" プロットを削除する ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("drag-horizontal-variant-icon", { staticClass: "icon handle-disp" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }