var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.isShowFirstTimeMessage
      ? _c("div", { staticClass: "message-box" }, [
          _c("div", { staticClass: "content" }, [
            _vm._v(
              " 自動字下げ機能をオフにする場合は、メニューの「自動字下げ機能」から設定をOFFに変更をお願いします。 "
            ),
          ]),
          _c("div", { staticClass: "nav" }, [
            _c("button", { staticClass: "button", on: { click: _vm.close } }, [
              _vm._v("閉じる"),
            ]),
          ]),
          _c(
            "svg",
            {
              staticClass: "arrow-svg",
              staticStyle: { "background-color": "transparent" },
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "50",
                height: "20",
                viewBox: "0 0 50 20",
              },
            },
            [_c("polyline", { attrs: { points: "0,20 50,0 50,20" } })]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }