var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "plot-edit" }, [
    _c(
      "div",
      { staticClass: "plot-top-area" },
      [
        _vm.plotId
          ? _c("input-text", {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.resetPlotNameStatus,
                  expression: "resetPlotNameStatus",
                },
              ],
              staticClass: "input plot-name-editing",
              class: { "plot-name": !_vm.changingPlotName },
              attrs: {
                label: "プロットの名称を入力してください",
                type: "text",
                maxlength: 120,
              },
              on: { input: _vm.input },
              nativeOn: {
                click: function ($event) {
                  return _vm.changePlotNameStatus.apply(null, arguments)
                },
              },
              model: {
                value: _vm.plotName,
                callback: function ($$v) {
                  _vm.plotName = $$v
                },
                expression: "plotName",
              },
            })
          : _c("div", { staticClass: "all-plot" }, [_vm._v("全体プロット")]),
        _c(
          "div",
          { staticClass: "btn-mode-area" },
          [
            !_vm.isSP
              ? _c("SelectBoxV2", {
                  staticClass: "selectbox",
                  attrs: { options: _vm.plotLayouts },
                  model: {
                    value: _vm.selectedLayout,
                    callback: function ($$v) {
                      _vm.selectedLayout = $$v
                    },
                    expression: "selectedLayout",
                  },
                })
              : _vm._e(),
            !_vm.isSP
              ? _c(
                  "div",
                  {
                    staticClass: "selectbox mode-box",
                    on: { click: _vm.modeClick },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "selected" },
                      [
                        _c(
                          "div",
                          { staticClass: "mode-text select-box-item" },
                          [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(_vm.modeName) + "モード"),
                            ]),
                          ]
                        ),
                        _c("ChevronDownIcon", {
                          staticClass: "caret",
                          class: { open: true },
                          attrs: { fillColor: "#669cd9" },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "button save-button",
                attrs: { disabled: !_vm.changed || _vm.plotName === "" },
                on: {
                  click: function ($event) {
                    return _vm.save(false)
                  },
                },
              },
              [_vm._v(" 保存する ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "plot-groups" },
      [
        _c(
          "draggable",
          {
            attrs: {
              group: "group",
              draggable: ".draggable-group",
              handle: ".handle",
              animation: 300,
              ghostClass: "ghost-group",
            },
            on: { start: _vm.onGroupDragStart, end: _vm.onGroupDragEnd },
            model: {
              value: _vm.plotGroups,
              callback: function ($$v) {
                _vm.plotGroups = $$v
              },
              expression: "plotGroups",
            },
          },
          [
            _c(
              "transition-group",
              { staticClass: "draggable", attrs: { name: "fade-in" } },
              _vm._l(_vm.plotGroups, function (plotGroup, index) {
                return _c(
                  "div",
                  {
                    key: `plot-group-${index}`,
                    staticClass: "draggable-group",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "plot-group-header" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "plot-group-name",
                            class: { "plot-group-handle": _vm.isCustom },
                          },
                          [
                            _c("div", { staticClass: "plot-group-name-row" }, [
                              _c(
                                "div",
                                { staticClass: "plot-group-name-row-first" },
                                [
                                  _vm.isCustom
                                    ? _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: plotGroup.name,
                                            expression: "plotGroup.name",
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          placeholder:
                                            "プロットグループの名称(起/序/第1章 etc)",
                                        },
                                        domProps: { value: plotGroup.name },
                                        on: {
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                plotGroup,
                                                "name",
                                                $event.target.value
                                              )
                                            },
                                            _vm.input,
                                          ],
                                        },
                                      })
                                    : _c("div", [
                                        _vm._v(
                                          "- " + _vm._s(plotGroup.name) + " -"
                                        ),
                                      ]),
                                ]
                              ),
                              _vm.isCustom
                                ? _c(
                                    "div",
                                    { staticClass: "menu-parent" },
                                    [
                                      _c("content-duplicate-icon", {
                                        staticClass: "icon mr",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onClickContentCopy(index)
                                          },
                                        },
                                      }),
                                      _c("plus-circle-outline-icon", {
                                        staticClass: "icon mr",
                                        on: {
                                          click: function ($event) {
                                            return _vm.addPlotGroup(index)
                                          },
                                        },
                                      }),
                                      _c("dots-horizontal-circle-icon", {
                                        staticClass: "icon light-gray",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickDisplayMenu(index)
                                          },
                                        },
                                      }),
                                      _vm.displayMenu === index
                                        ? _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "click-outside",
                                                  rawName: "v-click-outside",
                                                  value: _vm.onClickOutside,
                                                  expression: "onClickOutside",
                                                },
                                              ],
                                              staticClass: "menu",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deletePlotGroup(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " プロットグループを削除する "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: plotGroup.description,
                                  expression: "plotGroup.description",
                                },
                              ],
                              staticClass: "textarea-theme",
                              attrs: {
                                placeholder: "プロットグループの内容を記入",
                                maxlength: _vm.maxDescriptionLength,
                              },
                              domProps: { value: plotGroup.description },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      plotGroup,
                                      "description",
                                      $event.target.value
                                    )
                                  },
                                  _vm.input,
                                ],
                              },
                            }),
                          ]
                        ),
                        _vm.isCustom
                          ? _c("drag-horizontal-variant-icon", {
                              staticClass: "icon handle",
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.groupDragging,
                            expression: "!groupDragging",
                          },
                        ],
                        staticClass: "plots",
                      },
                      [
                        _c(
                          "draggable",
                          {
                            attrs: {
                              group: "item",
                              draggable: ".draggable-item",
                              handle: ".handle-disp",
                              animation: 300,
                              ghostClass: "ghost",
                            },
                            on: { end: _vm.onDragEnd },
                            model: {
                              value: plotGroup.plots,
                              callback: function ($$v) {
                                _vm.$set(plotGroup, "plots", $$v)
                              },
                              expression: "plotGroup.plots",
                            },
                          },
                          [
                            !_vm.isDetail
                              ? _c(
                                  "transition-group",
                                  {
                                    staticClass: "draggable",
                                    attrs: { name: "fade-in" },
                                  },
                                  _vm._l(plotGroup.plots, function (plot) {
                                    return _c("plot-item-card", {
                                      key: plot.key,
                                      class: {
                                        "draggable-item": !_vm.isInitial(plot),
                                      },
                                      attrs: { isInitial: _vm.isInitial(plot) },
                                      on: {
                                        input: function ($event) {
                                          return _vm.inputItem(
                                            plot,
                                            plotGroup.plots
                                          )
                                        },
                                        deleteItem: function ($event) {
                                          return _vm.deleteItem(plot, index)
                                        },
                                      },
                                      model: {
                                        value: plot.text,
                                        callback: function ($$v) {
                                          _vm.$set(plot, "text", $$v)
                                        },
                                        expression: "plot.text",
                                      },
                                    })
                                  }),
                                  1
                                )
                              : _c(
                                  "transition-group",
                                  {
                                    staticClass: "draggable-detail",
                                    attrs: { name: "fade-in" },
                                  },
                                  _vm._l(plotGroup.plots, function (plot, j) {
                                    return _c("plot-item-detail", {
                                      key: plot.key,
                                      class: {
                                        "draggable-item": !_vm.isInitial(plot),
                                      },
                                      attrs: {
                                        novelId: _vm.novelId,
                                        plotId: _vm.plotId,
                                        isInitial: _vm.isInitial(plot),
                                        child: plot.linkedPlotKey,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.inputItem(
                                            plot,
                                            plotGroup.plots
                                          )
                                        },
                                        deleteItem: function ($event) {
                                          return _vm.deleteItem(plot, index)
                                        },
                                        linkPlot: function ($event) {
                                          return _vm.linkPlot(
                                            $event,
                                            plot,
                                            index,
                                            j
                                          )
                                        },
                                        cancelLink: function ($event) {
                                          return _vm.cancelLink(plot, index, j)
                                        },
                                      },
                                      model: {
                                        value: plot.text,
                                        callback: function ($$v) {
                                          _vm.$set(plot, "text", $$v)
                                        },
                                        expression: "plot.text",
                                      },
                                    })
                                  }),
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }