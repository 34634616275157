var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [_c("novel-detail-edit", { attrs: { "novel-id": _vm.novelId } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }