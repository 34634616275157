var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "fade-fast" } }, [
    _vm.novel
      ? _c("div", [
          _c("div", { staticClass: "novel-detail" }, [
            _c(
              "div",
              { staticClass: "info-txt" },
              [
                _c("h2", [_vm._v("作品のジャンル・カテゴリ")]),
                _c("select-box", {
                  staticClass: "selectbox genre",
                  attrs: {
                    items: _vm.genreList,
                    keyAttr: "id",
                    placeholder: "ジャンルを選択してください",
                    selected: _vm.genre,
                    enableUnselected: "",
                  },
                  on: { select: _vm.selectGenre, unselect: _vm.unselectGenre },
                }),
                _c("multiple-select-box", {
                  staticClass: "selectbox category",
                  attrs: {
                    items: _vm.categoryList,
                    keyAttr: "id",
                    placeholder: "カテゴリを選択してください（３つまで選択可）",
                    max: 3,
                    selected: _vm.category,
                  },
                  on: { select: _vm.selectCategory },
                }),
                _c("h2", [_vm._v("タイトル")]),
                _c("text-field", {
                  attrs: { rows: 3, maxlength: 80 },
                  on: { input: _vm.change },
                  model: {
                    value: _vm.novel.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.novel, "title", $$v)
                    },
                    expression: "novel.title",
                  },
                }),
                _c("h2", [_vm._v("あらすじ")]),
                _c("text-field", {
                  attrs: { rows: 10, maxlength: 10000 },
                  on: { input: _vm.change },
                  model: {
                    value: _vm.novel.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.novel, "description", $$v)
                    },
                    expression: "novel.description",
                  },
                }),
                _c("h2", [_vm._v("制作状況")]),
                _c("select-box", {
                  staticClass: "selectbox",
                  attrs: {
                    items: _vm.workStatusList,
                    keyAttr: "id",
                    selected: _vm.workStatus,
                  },
                  on: { select: _vm.selectWorkStatus },
                }),
                _c(
                  "button",
                  {
                    staticClass: "button button-edit",
                    attrs: { disabled: !_vm.novel.title },
                    on: { click: _vm.submit },
                  },
                  [_vm._v("保存する")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "info-img" },
              [
                _c("image-selector", {
                  attrs: {
                    ratio: 148.0 / 105.0,
                    defaultImage: _vm.imagePath(),
                    placeholder: "/img/placeholders/novel.png",
                  },
                  on: { input: _vm.change },
                  model: {
                    value: _vm.image,
                    callback: function ($$v) {
                      _vm.image = $$v
                    },
                    expression: "image",
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }