var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-box" }, [
        _c("h3", [_vm._v("子プロットを作成・連携しますか？")]),
        _c(
          "div",
          { staticClass: "section dialog-body" },
          [
            _c("div", { staticClass: "attension" }, [
              _vm._v(
                " このプロットアイテムに紐づく子プロットの作成を行う場合は、作成方法を選択してください。 "
              ),
            ]),
            _c("select-box", {
              staticClass: "selectbox",
              attrs: {
                items: _vm.plots,
                keyAttr: "plotId",
                placeholder: "選択してください",
                selected: _vm.selectedPlots,
              },
              on: { select: _vm.selectPlot },
            }),
            _vm.selectedPlots && _vm.selectedPlots.plotId === "new"
              ? _c(
                  "div",
                  { staticClass: "input-container" },
                  [
                    _c("h2", [_vm._v("プロット形式を選択")]),
                    _c("select-box", {
                      staticClass: "selectbox",
                      class: { disabled: _vm.hasLimit },
                      attrs: {
                        items: _vm.plotModes,
                        keyAttr: "id",
                        placeholder: "選択してください",
                        selected: _vm.selectedMode,
                        disabled: _vm.hasLimit,
                      },
                      on: { select: _vm.selectMode },
                    }),
                    _vm.hasLimit
                      ? _c("div", { staticClass: "caution" }, [
                          _vm._v(
                            "※ 無料会員では『起承転結』のみ選択できます。"
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectedPlots && _vm.selectedPlots.plotId === "new"
              ? _c("div", { staticClass: "attension" }, [
                  _vm._v(
                    " 作成する子プロットのタイトルを入力してください。 タイトルは後から変更できます。 "
                  ),
                ])
              : _vm._e(),
            _vm.selectedPlots && _vm.selectedPlots.plotId === "new"
              ? _c("input-text", {
                  staticClass: "plot-name",
                  attrs: {
                    maxlength: 120,
                    label: "子プロットのタイトルを入力",
                  },
                  model: {
                    value: _vm.name,
                    callback: function ($$v) {
                      _vm.name = $$v
                    },
                    expression: "name",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "section button-area" }, [
          _c(
            "button",
            {
              staticClass: "button cancel",
              on: { click: _vm.onNegativeClick },
            },
            [_vm._v(_vm._s(_vm.negative))]
          ),
          _c("div", { staticClass: "border" }),
          _c(
            "button",
            {
              staticClass: "button primary",
              attrs: { disabled: !_vm.selectedPlots },
              on: { click: _vm.onPositiveClick },
            },
            [_vm._v(" " + _vm._s(_vm.positive) + " ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }