var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "list-root" },
    [
      _c("div", { staticClass: "head" }, [
        _c("div", { staticClass: "title ellipsis" }, [
          _vm._v("相関関係グループ一覧"),
        ]),
        _c("div", { staticClass: "edit" }, [
          !_vm.isEditing
            ? _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.isEditing = true
                    },
                  },
                },
                [_vm._v("並び替え")]
              )
            : _c("div", { on: { click: _vm.saveOrder } }, [_vm._v("保存する")]),
        ]),
      ]),
      _c("sortable-list", {
        staticClass: "list-container",
        attrs: {
          items: _vm.correlationGroupList,
          keyAttr: "correlationGroupId",
          disabled: !_vm.isEditing,
          extra: _vm.isEditing,
        },
        on: {
          "update:items": function ($event) {
            _vm.correlationGroupList = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ item, extra }) {
              return [
                _c("list-item", {
                  attrs: {
                    icon: "none",
                    label: item.name,
                    to: {
                      name: "correlations",
                      params: {
                        novelId: _vm.novelId,
                        correlationGroupId: item.correlationGroupId,
                      },
                    },
                    isActive:
                      item.correlationGroupId ===
                      _vm.selectedCorrelationGroupId,
                    showHandle: extra,
                    item: item,
                    isEditMode: _vm.isEditing,
                    disabledFolder: "",
                  },
                  on: {
                    delete: function ($event) {
                      return _vm.deleteCorrelationGroup(item.correlationGroupId)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "button",
        { staticClass: "create", on: { click: _vm.createCorrelationGroup } },
        [_vm._v("＋ 新規作成する")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }