var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "editpage" },
    [
      _vm.model
        ? _c("image-selector", {
            staticClass: "view",
            attrs: {
              ratio: 2.0 / 5.0,
              defaultImage: _vm.imagePath,
              placeholder: "/img/placeholders/worldview.png",
            },
            on: { input: _vm.change },
            model: {
              value: _vm.image,
              callback: function ($$v) {
                _vm.image = $$v
              },
              expression: "image",
            },
          })
        : _vm._e(),
      _vm.model
        ? _c("world-view-form", {
            attrs: { "novel-id": _vm.novelId },
            model: {
              value: _vm.model,
              callback: function ($$v) {
                _vm.model = $$v
              },
              expression: "model",
            },
          })
        : _vm._e(),
      _c(
        "button-default",
        {
          staticClass: "submit",
          attrs: { disabled: !_vm.model || !_vm.model.name },
          on: { click: _vm.submit },
        },
        [_vm._v("更新する")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }