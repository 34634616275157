var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "plot-page" },
    [
      _c("plot-list", {
        attrs: {
          novelId: _vm.novelId,
          plotId: _vm.plotId,
          subPlots: _vm.subPlots,
        },
      }),
      _vm.plot
        ? _c("plots", {
            staticClass: "plot",
            attrs: {
              "novel-id": _vm.novelId,
              plotId: _vm.plotId,
              plot: _vm.plot,
            },
          })
        : _vm._e(),
      _vm.isEnableMobile && !_vm.isHamburgerMenuOpen
        ? _c("slide-shared-link-menu", {
            staticClass: "slide-menu",
            attrs: { novelId: _vm.novelId, kind: "plot" },
          })
        : _vm._e(),
      _vm.isShowPublishSharedLink
        ? _c(
            "div",
            { staticClass: "dialog" },
            [
              _c("publish-shared-link", {
                staticClass: "dialog-content",
                attrs: { novelId: _vm.novelId, kind: "plot" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }