var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "fade-fast" } }, [
    _vm.novel
      ? _c("div", [
          _c("div", { staticClass: "novel-detail" }, [
            _c(
              "div",
              { staticClass: "info-img" },
              [
                _c("div", { staticClass: "work-status" }, [
                  _vm._v(_vm._s(_vm.workStatusName)),
                ]),
                _c("image-view", {
                  staticClass: "image",
                  attrs: {
                    src: _vm.imageKey,
                    placeholder: "/img/placeholders/novel.png",
                  },
                }),
                _vm.genre
                  ? _c("div", { staticClass: "novel-settings" }, [
                      _vm._v(_vm._s(_vm.genreName)),
                    ])
                  : _vm._e(),
                _vm.category
                  ? _c("div", { staticClass: "novel-settings" }, [
                      _vm._v(_vm._s(_vm.categoryName)),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "info-txt" }, [
              _c("h2", [_vm._v("タイトル")]),
              _c("div", { staticClass: "detail-title" }, [
                _vm._v(_vm._s(_vm.novel.title)),
              ]),
              _c("h2", [_vm._v("あらすじ")]),
              _c("div", { staticClass: "detail-description" }, [
                _vm._v(_vm._s(_vm.novel.description)),
              ]),
              _c(
                "div",
                { staticClass: "flex-row" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "button button-edit",
                      attrs: {
                        tag: "button",
                        to: {
                          name: "detailEdit",
                          param: { novelId: _vm.novelId },
                        },
                      },
                    },
                    [_vm._v(" 作品情報を編集する ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "content" }, [
            _c("hr", { staticClass: "dotted light-gray pc-only" }),
            _c(
              "div",
              { staticClass: "assosiate-container flex flex-column pc-only" },
              [
                _c("div", [
                  _vm.novelIdFromNolaNovel
                    ? _c("h3", [_vm._v("Nolaノベルの作品情報を更新する")])
                    : _c("h3", [
                        _vm._v(
                          "Nolaノベルに「" +
                            _vm._s(_vm.novel.title) +
                            "」を投稿する"
                        ),
                      ]),
                ]),
                _c("span", [
                  _vm._v(
                    " Nolaノベルは、作家と編集者がつながる小説投稿サイトです。 "
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    " 様々な企業の編集部が探している作品像を知り、編集者に向けて作品投稿できます。自分に合うレーベルを見つけたり、商業デビューに近づくきっかけとしてぜひご利用ください。 "
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    " なお、Nolaノベルと連携してもNola内のデータが作家様の許可なく投稿されるなどはございませんので、ご安心いただけますと幸いです。 "
                  ),
                ]),
                _c("div", { staticClass: "mt-1 flex flex-row gap-harf" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn post bold pointer",
                      on: {
                        click: function ($event) {
                          return _vm.postToNolaNovelButtonClick(true)
                        },
                      },
                    },
                    [
                      _vm.novelIdFromNolaNovel
                        ? _c("span", [_vm._v(" 更新する ")])
                        : _c("span", [_vm._v(" 投稿する ")]),
                    ]
                  ),
                  !_vm.novelIdFromNolaNovel
                    ? _c(
                        "div",
                        {
                          staticClass: "btn bold pointer",
                          on: {
                            click: function ($event) {
                              return _vm.postToNolaNovelButtonClick(false)
                            },
                          },
                        },
                        [_vm._v(" 連携する ")]
                      )
                    : _vm._e(),
                ]),
                _vm.novelIdFromNolaNovel
                  ? _c("div", [
                      _c("h4", [_vm._v("連携しているNolaノベルの作品ID")]),
                      _c(
                        "div",
                        { staticClass: "flex flex-row space-between" },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.navigateToNolaNovelLink,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.novelIdFromNolaNovel) + " "
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "undeline light-gray font-small pointer",
                              on: { click: _vm.delinkNolaNovelButtonClick },
                            },
                            [_vm._v(" 連携を解除する ")]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _c("hr", { staticClass: "dotted light-gray" }),
            _c("div", { staticClass: "delete-container flex flex-column" }, [
              _c("h3", [
                _vm._v("「" + _vm._s(_vm.novel.title) + "」を削除する"),
              ]),
              _c("span", [
                _vm._v(
                  " 以下のボタンからこの作品の情報を削除をすることができます。"
                ),
                _c("br"),
                _vm._v(
                  " 一度、作品情報の削除を実施するとデータの復元はできませんので、慎重にご判断ください。 "
                ),
              ]),
              _c("div", { staticClass: "mt-1 flex flex-row gap-harf" }, [
                _c(
                  "div",
                  {
                    staticClass: "btn delete bold pointer",
                    on: { click: _vm.deleteButtonClick },
                  },
                  [_vm._v("削除する")]
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }