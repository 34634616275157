import { render, staticRenderFns } from "./DeleteIcon.vue?vue&type=template&id=51c74b61"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/satoru/dev/Nola/nola-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51c74b61')) {
      api.createRecord('51c74b61', component.options)
    } else {
      api.reload('51c74b61', component.options)
    }
    module.hot.accept("./DeleteIcon.vue?vue&type=template&id=51c74b61", function () {
      api.rerender('51c74b61', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/atoms/DeleteIcon.vue"
export default component.exports