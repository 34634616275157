var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-box" }, [
        _c("div", { staticClass: "dialog-header" }, [
          _c("span", { staticClass: "font-bold" }, [
            _vm._v("原稿のエクスポート"),
          ]),
          _c(
            "span",
            { staticClass: "close", on: { click: _vm.onClickOutSide } },
            [_vm._v("× ウィンドウを閉じる")]
          ),
        ]),
        _c("div", { staticClass: "dialog-body" }, [
          _c("div", { staticClass: "description font-bold" }, [
            _vm._v("ダウンロードする原稿を選択してください"),
          ]),
          _c(
            "div",
            { staticClass: "manuscript-list" },
            _vm._l(_vm.manuscriptList, function (manuscript) {
              return _c(
                "div",
                {
                  key: manuscript.key,
                  staticClass: "list-item",
                  on: {
                    click: function ($event) {
                      return _vm.onClickManuscript(manuscript)
                    },
                  },
                },
                [
                  _c("checkbox-icon", {
                    attrs: { isChecked: _vm.isChecked(manuscript) },
                  }),
                  _c("div", { staticClass: "manuscript-title ellipsis" }, [
                    _vm._v(
                      " " +
                        _vm._s(manuscript.title || "（タイトル未設定）") +
                        " "
                    ),
                  ]),
                  _vm.orderNumber(manuscript) > 0
                    ? _c("div", { staticClass: "order-number" }, [
                        _vm._v(" " + _vm._s(_vm.orderNumber(manuscript)) + " "),
                      ])
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "dialog-footer" }, [
          _c("div", { staticClass: "font-bold" }, [_vm._v("詳細設定")]),
          _c(
            "div",
            { staticClass: "settings" },
            [
              _c("div", { staticClass: "setting-item" }, [
                _c("div", { staticClass: "font-bold" }, [
                  _vm._v("ファイル形式"),
                ]),
                _c("div", { staticClass: "format-select" }, [
                  _c(
                    "div",
                    {
                      staticClass: "radio-button",
                      on: {
                        click: function ($event) {
                          return _vm.onChangeFileType("pdf")
                        },
                      },
                    },
                    [
                      _c("radio-icon", {
                        attrs: {
                          value: "pdf",
                          selected: _vm.selectedFileType,
                          fill: _vm.checkBoxColor,
                        },
                      }),
                      _c("label", [_vm._v("PDF")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "radio-button",
                      on: {
                        click: function ($event) {
                          return _vm.onChangeFileType("txt")
                        },
                      },
                    },
                    [
                      _c("radio-icon", {
                        attrs: {
                          value: "txt",
                          selected: _vm.selectedFileType,
                          fill: _vm.checkBoxColor,
                        },
                      }),
                      _c("label", [_vm._v("TXT")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "radio-button",
                      on: {
                        click: function ($event) {
                          return _vm.onChangeFileType("word")
                        },
                      },
                    },
                    [
                      _c("radio-icon", {
                        attrs: {
                          value: "word",
                          selected: _vm.selectedFileType,
                          fill: _vm.checkBoxColor,
                        },
                      }),
                      _c("label", [_vm._v("Word")]),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "setting-item" }, [
                _c("div", { staticClass: "font-bold" }, [
                  _vm._v("タイトルの表示"),
                ]),
                _c("div", { staticClass: "format-select" }, [
                  _c(
                    "div",
                    {
                      staticClass: "radio-button",
                      on: {
                        click: function ($event) {
                          return _vm.onChangeShowTitle(true)
                        },
                      },
                    },
                    [
                      _c("radio-icon", {
                        attrs: {
                          value: true,
                          selected: _vm.selectedShowTitle,
                          fill: _vm.checkBoxColor,
                        },
                      }),
                      _c("label", [_vm._v("表示あり")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "radio-button",
                      on: {
                        click: function ($event) {
                          return _vm.onChangeShowTitle(false)
                        },
                      },
                    },
                    [
                      _c("radio-icon", {
                        attrs: {
                          value: false,
                          selected: _vm.selectedShowTitle,
                          fill: _vm.checkBoxColor,
                        },
                      }),
                      _c("label", [_vm._v("表示なし")]),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm.selectedFileType === "pdf"
                ? [
                    _c("div", { staticClass: "setting-item" }, [
                      _c("div", { staticClass: "font-bold" }, [
                        _vm._v("原稿の表示形式（PDFのみ）"),
                      ]),
                      _c("div", { staticClass: "format-select" }, [
                        _c(
                          "div",
                          {
                            staticClass: "radio-button",
                            on: {
                              click: function ($event) {
                                return _vm.onChangeFormat("vertical")
                              },
                            },
                          },
                          [
                            _c("radio-icon", {
                              attrs: {
                                value: "vertical",
                                selected: _vm.selectedFormat,
                                fill: _vm.checkBoxColor,
                              },
                            }),
                            _c("label", [_vm._v("縦書き表示")]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "radio-button",
                            on: {
                              click: function ($event) {
                                return _vm.onChangeFormat("horizontal")
                              },
                            },
                          },
                          [
                            _c("radio-icon", {
                              attrs: {
                                value: "horizontal",
                                selected: _vm.selectedFormat,
                                fill: _vm.checkBoxColor,
                              },
                            }),
                            _c("label", [_vm._v("横書き表示")]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm.selectedFormat === "vertical"
                      ? _c("div", { staticClass: "setting-item" }, [
                          _c("div", { staticClass: "font-bold" }, [
                            _vm._v("原稿用紙換算（PDFのみ）"),
                          ]),
                          _c("div", [
                            _vm._v(
                              "原稿画面右下のメニューから設定してください"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                : [
                    _c("div", { staticClass: "setting-item" }, [
                      _c("div", { staticClass: "font-bold" }, [
                        _vm._v("原稿ごとにファイル生成"),
                      ]),
                      _c("div", { staticClass: "format-select" }, [
                        _c(
                          "div",
                          {
                            staticClass: "radio-button",
                            on: {
                              click: function ($event) {
                                return _vm.onChangeExportType("zip")
                              },
                            },
                          },
                          [
                            _c("radio-icon", {
                              attrs: {
                                value: "zip",
                                selected: _vm.selectedExportType,
                                fill: _vm.checkBoxColor,
                              },
                            }),
                            _c("label", [_vm._v("有効")]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "radio-button",
                            on: {
                              click: function ($event) {
                                return _vm.onChangeExportType("file")
                              },
                            },
                          },
                          [
                            _c("radio-icon", {
                              attrs: {
                                value: "file",
                                selected: _vm.selectedExportType,
                                fill: _vm.checkBoxColor,
                              },
                            }),
                            _c("label", [_vm._v("無効")]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
            ],
            2
          ),
          _c("div", { staticClass: "button-area" }, [
            _c(
              "button",
              {
                staticClass: "button primary",
                attrs: { disabled: !_vm.selectedManuscripts.length },
                on: { click: _vm.onClickDownload },
              },
              [_vm._v(" ダウンロードする ")]
            ),
          ]),
        ]),
      ]),
      _vm.isDownloading ? _c("masked-loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }