var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("div", { staticClass: "list-root" }, [
        _c("div", { staticClass: "head" }, [
          _c("div", { staticClass: "title" }, [_vm._v("相関関係一覧")]),
          _c(
            "div",
            { staticClass: "filter" },
            [
              _c("img", {
                attrs: { src: require("@/assets/img/icon/filter_list.svg") },
              }),
              _c("select-box", {
                attrs: {
                  items: _vm.filterCharacterItems(),
                  keyAttr: "characterId",
                  iconPlaceholder: "/img/placeholders/character.png",
                  filterMode: "",
                  selected: _vm.filterCharacter,
                },
                on: { select: _vm.selectFilterCharacter },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "list-container" },
          _vm._l(_vm.correlationList, function (item) {
            return _c("correlation-list-item", {
              key: item.correlationId,
              attrs: {
                characterA: _vm.getCharacterData(item.characterA),
                characterB: _vm.getCharacterData(item.characterB),
                relationshipAtoB: item.relationshipAtoB,
                relationshipBtoA: item.relationshipBtoA,
                to: {
                  name: "correlations",
                  params: {
                    novelId: _vm.novelId,
                    correlationGroupId: _vm.selectedCorrelationGroupId,
                    correlationId: item.correlationId,
                  },
                },
                isActive: item.correlationId === _vm.selectedCorrelationId,
              },
            })
          }),
          1
        ),
        _c("button", { staticClass: "create", on: { click: _vm.openForm } }, [
          _vm._v("＋ 相関関係を追加する"),
        ]),
      ]),
      _vm.isCreating || _vm.selectedCorrelationId
        ? _c("correlation-form", {
            key: _vm.selectedCorrelationId,
            staticClass: "floating-content",
            attrs: {
              novelId: _vm.novelId,
              selectedCorrelationGroupId: _vm.selectedCorrelationGroupId,
              selectedCorrelationId: _vm.selectedCorrelationId,
              items: _vm.characterItems,
              correlationList: _vm.correlationList,
              correlation: _vm.getCorrelationData(),
              correlationDiagram: _vm.correlationDiagram,
            },
            on: { close: _vm.closeForm, fetch: _vm.fetchCorrelationList },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }