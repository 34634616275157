var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "comment-list" }, [
    _c("div", { staticClass: "title-wrapper" }, [
      _c("span", { staticClass: "arrow", on: { click: _vm.back } }, [
        _vm._v("＜"),
      ]),
      _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.contentTitle))]),
    ]),
    _c(
      "div",
      { ref: "list", staticClass: "list" },
      [
        _vm.commentList.length
          ? _c(
              "transition-group",
              { attrs: { name: "tran" } },
              _vm._l(_vm.commentList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.commentId,
                    staticClass: "item",
                    class: { own: _vm.isOwnComment(item.userId) },
                  },
                  [
                    _vm.isEditing(item.commentId)
                      ? _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editTarget.comment,
                              expression: "editTarget.comment",
                            },
                          ],
                          staticClass: "textarea",
                          attrs: { rows: _vm.rows(_vm.editTarget.comment) },
                          domProps: { value: _vm.editTarget.comment },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.editTarget,
                                "comment",
                                $event.target.value
                              )
                            },
                          },
                        })
                      : _c("vue-markdown", {
                          staticClass: "comment",
                          attrs: { source: item.comment, html: false },
                        }),
                    _c("div", { staticClass: "sub" }, [
                      _vm.isEditing(item.commentId)
                        ? _c("div", { staticClass: "edit-mode" }, [
                            _c(
                              "div",
                              {
                                staticClass: "btn delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.onClickDelete(item)
                                  },
                                },
                              },
                              [_vm._v("削除する")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "btn save",
                                on: { click: _vm.updateComment },
                              },
                              [_vm._v("保存する")]
                            ),
                          ])
                        : _c("div", { staticClass: "normal-mode" }, [
                            _c("div", { staticClass: "post-data" }, [
                              _c("div", { staticClass: "created-at" }, [
                                _vm._v(_vm._s(_vm.createdAt(item.createdAt))),
                              ]),
                              _c("div", { staticClass: "user-name" }, [
                                _vm._v(_vm._s(item.name || "名無し")),
                              ]),
                            ]),
                            _vm.isOwnComment(item.userId)
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "btn edit",
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit(item)
                                      },
                                    },
                                  },
                                  [_vm._v("編集する")]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "btn reply",
                                    on: {
                                      click: function ($event) {
                                        return _vm.reply(item.comment)
                                      },
                                    },
                                  },
                                  [_vm._v("返信する")]
                                ),
                          ]),
                    ]),
                  ],
                  1
                )
              }),
              0
            )
          : _c("div", { staticClass: "description" }, [
              _c("div", [_vm._v("コメントはありません")]),
            ]),
      ],
      1
    ),
    _c("div", { staticClass: "comment-post" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.comment,
            expression: "comment",
          },
        ],
        ref: "textarea",
        staticClass: "textarea",
        attrs: { rows: _vm.rows(_vm.comment) },
        domProps: { value: _vm.comment },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.comment = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button submit",
          attrs: { disabled: !_vm.comment },
          on: { click: _vm.post },
        },
        [_vm._v("送信")]
      ),
    ]),
    _vm.isDeleting
      ? _c("div", { staticClass: "delete-mask" }, [
          _c("h2", [_vm._v("このコメントを削除しますか？")]),
          _c("div", [_vm._v("一度削除したコメントは復旧できません。")]),
          _c("div", { staticClass: "delete-controller" }, [
            _c(
              "button",
              {
                staticClass: "button delete",
                on: { click: _vm.deleteComment },
              },
              [_vm._v("削除する")]
            ),
            _c(
              "button",
              { staticClass: "button cancel", on: { click: _vm.cancelDelete } },
              [_vm._v("キャンセル")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }