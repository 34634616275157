var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "root" }, [
    _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("共有リンク一覧")]),
      _c(
        "span",
        { staticClass: "close", on: { click: _vm.closePublishSharedLink } },
        [_vm._v("× ウィンドウを閉じる")]
      ),
    ]),
    _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "shared-link-list" }, [
        _c(
          "div",
          { staticClass: "list" },
          [
            _vm.sharedLinkList.length === 0
              ? _c("div", { staticClass: "description" }, [
                  _c("span", [_vm._v("新しい共有リンクを発行しましょう")]),
                ])
              : _vm._e(),
            _vm.sharedLinkList.length
              ? _c(
                  "transition-group",
                  { attrs: { name: "tran" } },
                  _vm._l(_vm.sharedLinkList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.sharedLinkId,
                        staticClass: "list-item",
                        class: {
                          active:
                            _vm.selected &&
                            _vm.selected.sharedLinkId === item.sharedLinkId,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.select(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "name ellipsis" }, [
                          _vm._v(_vm._s(item.name || "NO TITLE")),
                        ]),
                        _c("div", { staticClass: "link-wrapper" }, [
                          _c("div", { staticClass: "link ellipsis" }, [
                            item.enabled
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.resolveSharedLinkPath(item),
                                      target: "_blank",
                                      rel: "noopener noreferrer",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.sharedLinkPath(item)) +
                                        " "
                                    ),
                                  ]
                                )
                              : _c("a", { staticClass: "disabled-link" }, [
                                  _vm._v("リンクの共有を停止中"),
                                ]),
                          ]),
                          item.enabled
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:copy",
                                      value: _vm.sharedLinkPath(item),
                                      expression: "sharedLinkPath(item)",
                                      arg: "copy",
                                    },
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:success",
                                      value: _vm.onCopy,
                                      expression: "onCopy",
                                      arg: "success",
                                    },
                                  ],
                                  staticClass: "copy",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/icon/content_copy.svg"),
                                      alt: "content-copy",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "create-shared-link",
            on: { click: _vm.createSharedLink },
          },
          [
            _c("span", [_vm._v("＋ 新規作成する")]),
            !_vm.plan
              ? _c("span", { staticClass: "note" }, [
                  _vm._v("（無料会員はアカウントあたり３つまでご利用可能）"),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "div",
        {
          key: _vm.selected ? _vm.selected.sharedLinkId : null,
          staticClass: "container",
        },
        [
          _c("div", { staticClass: "main" }, [
            _vm.selected
              ? _c("div", { staticClass: "shared-link-name" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "input-wrapper" },
                    [
                      _c("input-text", {
                        staticClass: "input",
                        attrs: {
                          label: "リンクの名称を入力してください",
                          type: "text",
                          maxlength: 50,
                        },
                        model: {
                          value: _vm.selected.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.selected, "name", $$v)
                          },
                          expression: "selected.name",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.selected
              ? _c("div", { staticClass: "manuscript-list" }, [
                  _c("div", { staticClass: "header-container" }, [
                    _c("div", { staticClass: "header-title" }, [
                      _vm._v("共有する原稿を選択してください"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "dropup-menu",
                        on: {
                          click: function ($event) {
                            return _vm.toggleMenu(_vm.SharedLinkKind.MANUSCRIPT)
                          },
                        },
                      },
                      [
                        _c("img", {
                          class: { open: _vm.isOpenManuscriptList },
                          attrs: {
                            src: require("@/assets/img/icon/expand_less.svg"),
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm.isOpenManuscriptList
                    ? _c(
                        "div",
                        { staticClass: "list" },
                        _vm._l(_vm.manuscriptList, function (manuscript) {
                          return _c(
                            "div",
                            {
                              key: manuscript.key,
                              staticClass: "list-item",
                              on: {
                                click: function ($event) {
                                  return _vm.onClickItem(
                                    manuscript,
                                    _vm.SharedLinkKind.MANUSCRIPT
                                  )
                                },
                              },
                            },
                            [
                              _c("checkbox-icon", {
                                attrs: {
                                  isChecked: _vm.isChecked(
                                    manuscript,
                                    _vm.SharedLinkKind.MANUSCRIPT
                                  ),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "manuscript-title ellipsis" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      manuscript.title || "（タイトル未設定）"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "header-container" }, [
                    _c("div", { staticClass: "header-title" }, [
                      _vm._v("共有するプロットを選択してください"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "dropup-menu",
                        on: {
                          click: function ($event) {
                            return _vm.toggleMenu(_vm.SharedLinkKind.PLOT)
                          },
                        },
                      },
                      [
                        _c("img", {
                          class: { open: _vm.isOpenPlotList },
                          attrs: {
                            src: require("@/assets/img/icon/expand_less.svg"),
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm.isOpenPlotList
                    ? _c(
                        "div",
                        { staticClass: "list" },
                        _vm._l(_vm.plotList, function (plot) {
                          return _c(
                            "div",
                            {
                              key: plot.plotId,
                              staticClass: "list-item",
                              on: {
                                click: function ($event) {
                                  return _vm.onClickItem(
                                    plot,
                                    _vm.SharedLinkKind.PLOT
                                  )
                                },
                              },
                            },
                            [
                              _c("checkbox-icon", {
                                attrs: {
                                  isChecked: _vm.isChecked(
                                    plot,
                                    _vm.SharedLinkKind.PLOT
                                  ),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "manuscript-title ellipsis" },
                                [
                                  _vm._v(
                                    _vm._s(plot.name || "（タイトル未設定）")
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.selected
              ? _c(
                  "div",
                  { staticClass: "warning" },
                  [
                    _c("warning-icon", { staticClass: "warning-icon" }),
                    _c("div", { staticClass: "warning-title" }, [
                      _vm._v("注意事項："),
                    ]),
                    _c("div", { staticClass: "warning-text" }, [
                      _vm._v(
                        " 複数人とリンクを共有する場合、投稿したコメントの内容は共有リンクを知っている全員が閲覧することができますのでご注意ください。 "
                      ),
                    ]),
                  ],
                  1
                )
              : _c("div", { staticClass: "description" }, [
                  _c("span", [_vm._v("共有リンク一覧から選択してください")]),
                ]),
          ]),
          _c("div", { staticClass: "controller" }, [
            _c("div", { staticClass: "confirm-action-buttons" }, [
              _vm.plan !== 0
                ? _c(
                    "button",
                    {
                      staticClass: "delete",
                      attrs: { disabled: !_vm.selected },
                      on: { click: _vm.onClickDelete },
                    },
                    [_c("delete-icon", { staticClass: "delete-icon" })],
                    1
                  )
                : _vm._e(),
              _vm.selected
                ? _c("div", [
                    _vm.selected.enabled
                      ? _c(
                          "button",
                          {
                            staticClass: "button change-status",
                            on: { click: _vm.onClickStop },
                          },
                          [_vm._v("共有を停止する")]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "button change-status",
                            on: { click: _vm.resumeSharedLink },
                          },
                          [_vm._v("共有を再開する")]
                        ),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "wrapper" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.sharedLinkPath(_vm.selected),
                      expression: "sharedLinkPath(selected)",
                      arg: "copy",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success",
                    },
                  ],
                  staticClass: "button copy",
                  attrs: { disabled: !_vm.selected || !_vm.selected.enabled },
                },
                [_vm._v(" リンクコピー ")]
              ),
              _c(
                "button",
                {
                  staticClass: "button save",
                  attrs: { disabled: !_vm.selected },
                  on: { click: _vm.save },
                },
                [_vm._v("保存")]
              ),
            ]),
          ]),
          _vm.isDeleting || _vm.isStopping
            ? _c("div", { staticClass: "mask" }, [
                _c("h1", [
                  _vm._v(
                    _vm._s(
                      _vm.isDeleting
                        ? "この共有リンクを削除しますか？"
                        : "このリンクの共有を停止しますか？"
                    )
                  ),
                ]),
                _c("div", [
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isDeleting
                            ? "一度削除した共有リンクは利用できなくなります。"
                            : `共有を停止するとリンクを送った相手も作品が確認できなくなります`
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isDeleting
                            ? "再度共有する場合は、新しいリンクを作成してください。"
                            : "なお、再度同じリンクを利用したい場合は、「共有を再開する」から再開することも可能です。"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _vm.isDeleting
                  ? _c("div", { staticClass: "mask-controller" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button danger",
                          on: { click: _vm.deleteSharedLink },
                        },
                        [_vm._v("削除する")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "button cancel",
                          on: { click: _vm.cancelDelete },
                        },
                        [_vm._v("キャンセル")]
                      ),
                    ])
                  : _c("div", { staticClass: "mask-controller" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button danger",
                          on: { click: _vm.stopSharedLink },
                        },
                        [_vm._v("停止する")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "button cancel",
                          on: { click: _vm.cancelStop },
                        },
                        [_vm._v("キャンセル")]
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
      _c("span", { staticClass: "info-belt", class: { visible: _vm.copied } }, [
        _vm._v("共有リンクをクリップボードにコピーしました"),
      ]),
      _c("span", { staticClass: "info-belt", class: { visible: _vm.saved } }, [
        _vm._v("保存しました"),
      ]),
      _c(
        "span",
        { staticClass: "info-belt", class: { visible: _vm.deleted } },
        [_vm._v("削除しました")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "header-container" }, [
      _c("div", { staticClass: "header-title" }, [
        _vm._v("共有リンクの名称を入力してください"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }