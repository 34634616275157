
import Vue from "vue";
import { Manuscript, ManuscriptSetting, Novel, Plan } from "@/lib/models";
import RadioIcon from "@/components/atoms/RadioIcon.vue";
import CheckboxIcon from "@/components/atoms/CheckboxIcon.vue";
import { Dialog } from "@/lib/utils";
import DialogVue from "@/components/ui/Dialog.vue";
import MaskedLoading from "@/components/atoms/MaskedLoading.vue";
import { generateDocx } from "@/lib/utils/generator/docx";
import axiosBase from "axios";
import { saveAs } from "file-saver";
import PizZip from "pizzip";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_IMAGE_DOWNLOAD_API_ENDPOINT,
  headers: { "Content-Type": "application/json" },
});

const FileType = {
  PDF: "pdf",
  TXT: "txt",
  WORD: "word",
} as const;
type TFileType = typeof FileType[keyof typeof FileType];

const WritingFormat = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal",
} as const;
type TWritingFormat = typeof WritingFormat[keyof typeof WritingFormat];

const ExportType = {
  ZIP: "zip",
  FILE: "file",
} as const;
type TExportType = typeof ExportType[keyof typeof ExportType];

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { RadioIcon, CheckboxIcon, MaskedLoading },
  props: {
    novelId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedManuscripts: [],
      selectedFileType: FileType.PDF,
      selectedShowTitle: true,
      selectedFormat: WritingFormat.VERTICAL,
      selectedExportType: ExportType.ZIP,
      checkBoxColor: "#707070",
      isDownloading: false,
      selectedShowNovelTitle: true,
      selectedManuscriptPaperBorder: true,
      selectedManuscriptPageNumber: true,
      selectedManuscriptPageSettings: true,
      selectedNolaBrand: true,
    };
  },
  methods: {
    onClickOutSide() {
      this.$close(true);
    },
    onChangeFileType(fileType) {
      this.selectedFileType = fileType;
    },
    onChangeShowTitle(showTitle) {
      this.selectedShowTitle = showTitle;
    },
    onChangeFormat(format) {
      this.selectedFormat = format;
    },
    onChangeExportType(exportType) {
      this.selectedExportType = exportType;
    },
    onClickManuscript(manuscript) {
      if (this.selectedManuscripts.some((x) => x.key === manuscript.key)) {
        this.selectedManuscripts = this.selectedManuscripts.filter((x) => x.key !== manuscript.key);
      } else {
        this.selectedManuscripts.push(manuscript);
      }
    },
    async onClickDownload() {
      this.isDownloading = true;
      const zip = new PizZip();

      try {
        const manuscripts = this.selectedManuscripts;
        const fileType = this.selectedFileType;
        const showTitle = this.selectedShowTitle;
        const format = this.selectedFormat;
        const exportType = this.selectedExportType;
        const { manuscriptSetting } = this;

        switch (fileType) {
          case FileType.PDF: {
            // PDFファイルの作成
            const response = await axios.post("/exportManuscriptPdf", {
              manuscripts,
              format,
              showTitle,
              manuscriptSetting,
              ...(this.isVerticalFormat && {
                verticalFormatOptions: {
                  showNovelTitle: this.selectedShowNovelTitle,
                  novelTitle: this.novelTitle,
                  showManuscriptPaperBorder: this.selectedManuscriptPaperBorder,
                  showManuscriptPageNumber: this.selectedManuscriptPageNumber,
                  showManuscriptPageSettings: this.selectedManuscriptPageSettings,
                  showNolaBrand: this.selectedNolaBrand,
                },
              }),
            });

            const fileName = this.fileName("pdf");
            saveAs(this.base64ToBlob(response.data), fileName);

            break;
          }

          case FileType.TXT: {
            switch (exportType) {
              case ExportType.ZIP: {
                // txtファイルをzipファイルにまとめる
                manuscripts.forEach((manuscript) => {
                  const title = manuscript.title || "(タイトル未設定)";
                  const content = `${showTitle ? `${title}\n\n` : ""}${manuscript.content}`;
                  zip.file(`${title}.txt`, content);
                });

                const zipFile = zip.generate({ type: "blob" });
                saveAs(zipFile, `${this.novelTitle}.zip`);

                break;
              }

              case ExportType.FILE: {
                // 原稿を1つのテキストにまとめる
                const contents = manuscripts
                  .map(
                    (manuscript) =>
                      `${showTitle ? `${manuscript.title || "(タイトル未設定)"}\n\n` : ""}${manuscript.content}`
                  )
                  .join("\n\n");

                // テキストファイルの作成
                const txtFile = new Blob([contents], { type: "text/plain" });

                const fileName = this.fileName("txt");
                saveAs(txtFile, fileName);

                break;
              }

              default:
                break;
            }

            break;
          }

          case FileType.WORD: {
            switch (exportType) {
              case ExportType.ZIP: {
                const promiseArray = manuscripts.map(async (manuscript) => {
                  const title = manuscript.title || "(タイトル未設定)";
                  // Wordファイルの作成
                  const docx = await generateDocx([manuscript], showTitle);

                  // BlobをArrayBufferに変換
                  const arrayBuffer = await this.blobToArrayBuffer(docx);

                  zip.file(`${title}.docx`, arrayBuffer);
                });
                await Promise.all(promiseArray);

                const zipFile = zip.generate({ type: "blob" });
                saveAs(zipFile, `${this.novelTitle}.zip`);

                break;
              }

              case ExportType.FILE: {
                // Wordファイルの作成
                const docx = await generateDocx(manuscripts, showTitle);

                const fileName = this.fileName("docx");
                saveAs(docx, fileName);

                break;
              }

              default:
                break;
            }
            break;
          }

          default:
            throw new Error("invalid FileType");
        }
      } catch (e) {
        const confirmDialog = new Dialog(DialogVue);
        const options = {
          title: "エラー",
          content: e,
        };
        confirmDialog.show(options);
      } finally {
        this.isDownloading = false;
      }
    },
    base64ToBlob(base64: string) {
      /** 日本語対応 */
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);

      const uint8Array = Buffer.from(base64, "base64");
      return new Blob([bom, uint8Array.buffer], {
        type: "application/pdf",
      });
    },
    blobToArrayBuffer(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    },
    onChangeShowNovelTitle(showNovelTitle) {
      this.selectedShowNovelTitle = showNovelTitle;
    },
    onChangeManuscriptPaperBorder(showManuscriptPaperBorder) {
      this.selectedManuscriptPaperBorder = showManuscriptPaperBorder;
    },
    onChangeManuscriptPageNumber(showManuscriptPageNumber) {
      this.selectedManuscriptPageNumber = showManuscriptPageNumber;
    },
    onChangeManuscriptPageSettings(showManuscriptPageSettings) {
      this.selectedManuscriptPageSettings = showManuscriptPageSettings;
    },
    onChangeNolaBrand(showNolaBrand) {
      this.selectedNolaBrand = showNolaBrand;
    },
    showPremiumFeatureDialog() {
      this.$close(true);
      this.$router.push({ name: "subscriptionAnnounce", query: { from: "editor" } });
    },
  },
  computed: {
    novelTitle() {
      const novel = this.$store.getters["novelModule/novel"](this.novelId) as Novel;
      return novel.title;
    },
    manuscriptList() {
      return this.$store.getters["manuscriptModule/manuscriptList"];
    },
    manuscriptSetting() {
      return this.$store.getters["novelModule/manuscriptSetting"](this.novelId) as ManuscriptSetting;
    },
    isChecked() {
      return (manuscript) => {
        const { selectedManuscripts } = this;
        return selectedManuscripts.some((x) => x.key === manuscript.key);
      };
    },
    orderNumber() {
      return (manuscript) => {
        const { selectedManuscripts } = this;
        return selectedManuscripts.findIndex((x) => x.key === manuscript.key) + 1;
      };
    },
    fileName() {
      return (extension) => {
        const { novelTitle, selectedManuscripts } = this;
        const firstManuscriptName = selectedManuscripts[0].title || "(タイトル未設定)";
        const isMultiple = selectedManuscripts.length > 1;
        return `${novelTitle}_${firstManuscriptName}${isMultiple ? "（複数原稿選択）" : ""}.${extension}`;
      };
    },
    plan() {
      return this.$store.getters["userModule/plan"] as Plan;
    },
    isFree() {
      return this.plan === Plan.free;
    },
    isVerticalFormat() {
      return this.selectedFormat === WritingFormat.VERTICAL;
    },
  },
});

interface Props {
  novelId: string;
}

interface Data {
  selectedManuscripts: Manuscript[];
  selectedFileType: TFileType;
  selectedShowTitle: boolean;
  selectedFormat: TWritingFormat;
  selectedExportType: TExportType;
  checkBoxColor: string;
  isDownloading: boolean;
  selectedShowNovelTitle: boolean;
  selectedManuscriptPaperBorder: boolean;
  selectedManuscriptPageNumber: boolean;
  selectedManuscriptPageSettings: boolean;
  selectedNolaBrand: boolean;
}

interface Methods {
  onClickOutSide: () => void;
  onChangeFileType(fileType: TFileType): void;
  onChangeShowTitle(showTitle: boolean): void;
  onChangeFormat(format: TWritingFormat): void;
  onChangeExportType(exportType: TExportType): void;
  onClickManuscript(manuscript: Manuscript): void;
  onClickDownload(): void;
  base64ToBlob(base64: string): Blob;
  blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer>;
  onChangeShowNovelTitle(showNovelTitle: boolean): void;
  onChangeManuscriptPaperBorder(showManuscriptPaperBorder: boolean): void;
  onChangeManuscriptPageNumber(showManuscriptPageNumber: boolean): void;
  onChangeManuscriptPageSettings(showManuscriptPageSettings: boolean): void;
  onChangeNolaBrand(showNolaBrand: boolean): void;
  showPremiumFeatureDialog(): void;
}

interface Computed {
  novelTitle: string;
  manuscriptList: Manuscript[];
  manuscriptSetting: ManuscriptSetting;
  isChecked(manuscript: Manuscript): boolean;
  orderNumber(manuscript: Manuscript): number;
  fileName(extension: string): string;
  plan: Plan;
  isFree: boolean;
  isVerticalFormat: boolean;
}

export type ExportManuscriptDialogProps = Props;
