var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "ai-feedback-container" }, [
    !_vm.feedbackReceived
      ? _c("div", { staticClass: "feedback-section" }, [
          _vm.isLoading
            ? _c("div", { staticClass: "loading-overlay" }, [
                _c(
                  "div",
                  { staticClass: "loading-container" },
                  [
                    _c("lottie", {
                      attrs: {
                        animationData: require("@/assets/img/manuscript-analysis/loading.json"),
                      },
                    }),
                    _c("p", [_vm._v("感想を作成中…")]),
                  ],
                  1
                ),
              ])
            : _c("div", [
                _vm._m(0),
                _c("hr"),
                _c("div", { staticClass: "ai-feedback-body" }, [
                  _c("div", { staticClass: "feedback-info" }, [
                    _c("div", { staticClass: "feedback-info-text" }, [
                      _c("span", { staticClass: "description" }, [
                        _vm._v(
                          "最大" +
                            _vm._s(_vm.isFree ? 1 : 10) +
                            "万字分の原稿データを選択し、利用できます。"
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          "残り " +
                            _vm._s(
                              _vm.formatNumberWithCommas(
                                _vm.remainingAnalysisContents
                              )
                            ) +
                            " / " +
                            _vm._s(
                              _vm.formatNumberWithCommas(
                                _vm.maxAnalysisContents
                              )
                            ) +
                            "字"
                        ),
                      ]),
                    ]),
                  ]),
                  _vm.isOverLimit
                    ? _c("p", { staticClass: "over-limit-message" }, [
                        _vm._v("選択した原稿の文字数が上限を超えています。"),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "checkbox-list" },
                    _vm._l(_vm.items, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.key,
                          staticClass: "checkbox-item",
                          class: { disabled: item.contentLength === 0 },
                        },
                        [
                          _c("label", { staticClass: "checkbox-wrapper" }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                disabled: item.contentLength === 0,
                              },
                              domProps: { checked: item.checked },
                              on: {
                                change: (event) =>
                                  _vm.handleCheckboxChange(
                                    item,
                                    event.target.checked
                                  ),
                              },
                            }),
                            _c("span", { staticClass: "checkmark" }, [
                              _vm._v(_vm._s(_vm.getCheckmarkNumber(item))),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "manuscript-title-wrapper" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "manuscript-title",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCheckboxChange(
                                        item,
                                        !item.checked
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.title) + " ")]
                              ),
                              _c("span", { staticClass: "word-count" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatNumberWithCommas(
                                      item.contentLength
                                    )
                                  ) + "字"
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._m(1),
                  _c("div", { staticClass: "center-align" }, [
                    _c("div", { staticClass: "button-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "feedback-button",
                          class: {
                            disabled: _vm.isUnselected || _vm.isOverLimit,
                          },
                          on: { click: _vm.onSubmitFeedback },
                        },
                        [_vm._v(" 感想をもらう ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "feedback-button close",
                          on: { click: _vm.closeMessage },
                        },
                        [_vm._v("閉じる")]
                      ),
                    ]),
                    _vm.isFree
                      ? _c("p", { staticClass: "ai-feedback-plan-note" }, [
                          _vm._v(" 無料会員では、"),
                          _c("b", [_vm._v("1万")]),
                          _vm._v(
                            "文字まで読者AIからフィードバックをもらえます。"
                          ),
                          _c("br"),
                          _vm._v(
                            "Nolaプレミアムに登録すると毎月10万文字までご利用できます。 "
                          ),
                        ])
                      : _c("p", { staticClass: "ai-feedback-plan-note" }, [
                          _vm._v(" プレミアム会員では、毎月"),
                          _c("b", [_vm._v("10万")]),
                          _vm._v(
                            "文字まで読者AIからフィードバックをもらえます。 "
                          ),
                        ]),
                    _vm.isFree
                      ? _c(
                          "a",
                          {
                            staticClass: "plan-link",
                            on: { click: _vm.showPremiumFeatureDialog },
                          },
                          [_vm._v("プラン変更はこちら")]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
        ])
      : _c("div", { staticClass: "feedback-section" }, [
          _vm._m(2),
          _c("hr"),
          _c("div", { staticClass: "feedback-card" }, [
            _c("div", { staticClass: "feedback-header" }, [
              _c("img", {
                staticClass: "feedback-icon",
                attrs: {
                  src: require("@/assets/img/manuscript-analysis/yomisuke_icon.png"),
                  alt: "AI Feedback",
                },
              }),
              _vm.analysisResult
                ? _c("div", { staticClass: "feedback-title" }, [
                    _c("h4", [
                      _vm._v("「" + _vm._s(_vm.novelTitle) + "」を読んだ感想"),
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.analysisResult.createdAt))]),
                  ])
                : _vm._e(),
            ]),
            _vm.analysisResult
              ? _c("div", {
                  staticClass: "feedback-content",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(
                        _vm.convertNewlinesToBr(
                          _vm.analysisResult.manuscriptAnalysisResult
                        )
                      )
                    ),
                  },
                })
              : _vm._e(),
          ]),
          _c("div", { staticClass: "button-group" }, [
            _c(
              "button",
              { staticClass: "feedback-button", on: { click: _vm.backToTop } },
              [_vm._v("機能のトップページに戻る")]
            ),
            _c(
              "button",
              {
                staticClass: "feedback-button close",
                on: { click: _vm.closeMessage },
              },
              [_vm._v("閉じる")]
            ),
          ]),
        ]),
    _c("div", { staticClass: "past-feedback-section" }, [
      _c("h2", [_vm._v("過去のフィードバック")]),
      _vm.pastFeedbacks.length === 0
        ? _c("div", { staticClass: "no-feedback-message" }, [
            _vm._v(
              " まだフィードバックがありません。読者AIに原稿を渡して、感想メッセージを受け取ってみよう！ "
            ),
          ])
        : _c(
            "div",
            { staticClass: "past-feedback-list" },
            _vm._l(_vm.pastFeedbacks, function (feedback) {
              return _c(
                "div",
                {
                  key: feedback.manuscriptAnalysisId,
                  staticClass: "past-feedback-item",
                },
                [
                  _c("div", { staticClass: "past-feedback-header" }, [
                    _c("img", {
                      staticClass: "feedback-icon",
                      attrs: {
                        src: require("@/assets/img/manuscript-analysis/yomisuke_icon.png"),
                        alt: "AI Feedback",
                      },
                    }),
                    _c("div", { staticClass: "past-feedback-title" }, [
                      _c("p", [_vm._v(_vm._s(feedback.createdAt))]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "past-feedback-content",
                      class: { "white-space": !feedback.isShort },
                    },
                    [
                      feedback.isShort
                        ? _c(
                            "p",
                            { class: { "short-text": feedback.isShort } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.truncate(
                                      feedback.manuscriptAnalysisResult
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _c("p", [
                            _vm._v(_vm._s(feedback.manuscriptAnalysisResult)),
                          ]),
                    ]
                  ),
                  feedback.isToggleVisible
                    ? _c(
                        "div",
                        {
                          staticClass: "short-toggle-button",
                          on: {
                            click: function ($event) {
                              return _vm.toggleFeedbackDisplay(
                                feedback.manuscriptAnalysisId
                              )
                            },
                          },
                        },
                        [
                          !feedback.isShort
                            ? _c(
                                "svg",
                                {
                                  staticClass: "bi bi-chevron-up",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "currentColor",
                                    viewBox: "0 0 16 16",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      d:
                                        "M1.646 11.354a.5.5 0 0 1 0-.708L8 4.293l5.646 5.646a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708 0z",
                                    },
                                  }),
                                ]
                              )
                            : _c(
                                "svg",
                                {
                                  staticClass: "bi bi-chevron-down",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "currentColor",
                                    viewBox: "0 0 16 16",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      d:
                                        "M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z",
                                    },
                                  }),
                                ]
                              ),
                          _c("button", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  feedback.isShort
                                    ? "すべて表示する"
                                    : "表示を短くする"
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "ai-feedback-header" }, [
      _c("img", {
        staticClass: "ai-feedback-icon",
        attrs: {
          src: require("@/assets/img/manuscript-analysis/yomisuke_theme.png"),
          alt: "AI Feedback",
        },
      }),
      _c("h2", [
        _vm._v("AI読者ネコの ヨミスケ から"),
        _c("br"),
        _vm._v("作品の感想メッセージをもらいましょう"),
      ]),
      _c("p", { staticClass: "feedback-description" }, [
        _vm._v(" 任意の原稿を選択して、AI読者ネコからの感想を受け取れます。"),
        _c("br"),
        _vm._v("まだ不完全なため的を射ていない感想があるかもしれませんが、"),
        _c("br"),
        _vm._v("参考程度にぜひご利用いただけますと幸いです。 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "ai-feedback-note" }, [
      _vm._v(" ※ 原稿は物語の流れに合わせて、順番通り選択してください。"),
      _c("br"),
      _vm._v(
        " ※ 本機能のご利用にあたり、原稿データがAIの学習に利用されることはございません。"
      ),
      _c("br"),
      _vm._v(" ※ 表示されている文字数は、空白・ルビ記号等を含む文字数です。"),
      _c("br"),
      _vm._v(
        " ※ 小説作品のみご利用が可能です。その他の文体を読み込むとエラーになる場合がありますが、ご利用可能文字数は消費されますのでご注意ください。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "ai-feedback-header" }, [
      _c("h2", [_vm._v("感想メッセージが届きました")]),
      _c("p", { staticClass: "feedback-description" }, [
        _vm._v(" AI読者ネコが読んだ作品の感想が届きました。"),
        _c("br"),
        _vm._v("まだ精度も低いため、的外れな内容もあるかもしれませんが、"),
        _c("br"),
        _vm._v("あくまで制作の参考としてご確認いただけますと幸いです。 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }