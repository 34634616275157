var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("theme-editor", {
    attrs: { slot: "main", novelId: _vm.novelId },
    slot: "main",
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }