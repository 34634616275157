var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "correlation-form" }, [
    _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "title ellipsis", on: { click: _vm.close } }, [
        _vm._v("× ウィンドウを閉じる"),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "diagram" },
      [
        _c("correlation-list-item", {
          attrs: {
            previewMode: "",
            showPlaceholder: !_vm.isPreview,
            characterA: _vm.selectedCharacterA,
            characterB: _vm.selectedCharacterB,
            relationshipAtoB: _vm.relationshipAtoB,
            relationshipBtoA: _vm.relationshipBtoA,
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "input-form" }, [
      !_vm.isPreview
        ? _c("div", [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("label", { staticClass: "label" }, [
                  _vm._v("登場人物Aを選択してください"),
                ]),
                _c("select-box", {
                  staticClass: "selectbox",
                  attrs: {
                    items: _vm.filterCharacterAItems(),
                    keyAttr: "characterId",
                    placeholder: "登場人物を選択してください",
                    iconPlaceholder: "/img/placeholders/character.png",
                    selected: _vm.selectedCharacterA,
                  },
                  on: { select: _vm.selectCharacterA },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("label", { staticClass: "label" }, [
                  _vm._v("登場人物Bを選択してください"),
                ]),
                _c("select-box", {
                  staticClass: "selectbox",
                  attrs: {
                    items: _vm.filterCharacterBItems(),
                    keyAttr: "characterId",
                    placeholder: "登場人物を選択してください",
                    iconPlaceholder: "/img/placeholders/character.png",
                    selected: _vm.selectedCharacterB,
                  },
                  on: { select: _vm.selectCharacterB },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "column" }, [
        _c("label", { staticClass: "label" }, [_vm._v("A→Bの関係性")]),
        _c(
          "div",
          { staticClass: "relationship" },
          [
            _vm.isPreview
              ? _c("input-textarea", {
                  staticClass: "input memo preview",
                  attrs: {
                    rows: 2,
                    label: "未記入",
                    maxlength: 25,
                    readonly: "",
                  },
                  model: {
                    value: _vm.relationshipAtoB.relationship,
                    callback: function ($$v) {
                      _vm.$set(_vm.relationshipAtoB, "relationship", $$v)
                    },
                    expression: "relationshipAtoB.relationship",
                  },
                })
              : _c("input-text", {
                  staticClass: "input",
                  attrs: {
                    label: "関係性を入力してください",
                    type: "text",
                    maxlength: 25,
                  },
                  model: {
                    value: _vm.relationshipAtoB.relationship,
                    callback: function ($$v) {
                      _vm.$set(_vm.relationshipAtoB, "relationship", $$v)
                    },
                    expression: "relationshipAtoB.relationship",
                  },
                }),
            !_vm.isPreview
              ? _c("color-picker", {
                  attrs: { selected: _vm.relationshipAtoB.color },
                  on: { select: _vm.selectColorRelationshipAtoB },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "column" }, [
        _c("label", { staticClass: "label" }, [_vm._v("B→Aの関係性")]),
        _c(
          "div",
          { staticClass: "relationship" },
          [
            _vm.isPreview
              ? _c("input-textarea", {
                  staticClass: "input memo preview",
                  attrs: {
                    rows: 2,
                    label: "未記入",
                    maxlength: 25,
                    readonly: "",
                  },
                  model: {
                    value: _vm.relationshipBtoA.relationship,
                    callback: function ($$v) {
                      _vm.$set(_vm.relationshipBtoA, "relationship", $$v)
                    },
                    expression: "relationshipBtoA.relationship",
                  },
                })
              : _c("input-text", {
                  staticClass: "input",
                  attrs: {
                    label: "関係性を入力してください",
                    type: "text",
                    maxlength: 25,
                  },
                  model: {
                    value: _vm.relationshipBtoA.relationship,
                    callback: function ($$v) {
                      _vm.$set(_vm.relationshipBtoA, "relationship", $$v)
                    },
                    expression: "relationshipBtoA.relationship",
                  },
                }),
            !_vm.isPreview
              ? _c("color-picker", {
                  attrs: { selected: _vm.relationshipBtoA.color },
                  on: { select: _vm.selectColorRelationshipBtoA },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c("label", { staticClass: "label" }, [_vm._v("二人の関係性")]),
          _c("input-textarea", {
            class: { input: true, memo: true, preview: _vm.isPreview },
            attrs: {
              rows: 5,
              label: _vm.isPreview
                ? "未記入"
                : "こちらに二人の関係性を記載できます",
              maxlength: 2000,
              readonly: _vm.isPreview,
            },
            model: {
              value: _vm.memo,
              callback: function ($$v) {
                _vm.memo = $$v
              },
              expression: "memo",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "save" }, [
      _vm.isPreview
        ? _c("div", { staticClass: "button-container" }, [
            _c("button", { staticClass: "button", on: { click: _vm.edit } }, [
              _vm._v("編集する"),
            ]),
            _c(
              "button",
              {
                staticClass: "button danger",
                on: { click: _vm.deleteCorrelation },
              },
              [_vm._v("このデータを削除する")]
            ),
          ])
        : _c(
            "button",
            {
              staticClass: "button",
              attrs: { disabled: _vm.validation() },
              on: {
                click: function ($event) {
                  _vm.selectedCorrelationId
                    ? _vm.updateCorrelation()
                    : _vm.createCorrelation()
                },
              },
            },
            [_vm._v(" 保存する ")]
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }