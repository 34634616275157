var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("p", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
    _vm.subLabel
      ? _c("p", { staticClass: "sub-label" }, [_vm._v(_vm._s(_vm.subLabel))])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "input" },
      [
        _vm._t("default"),
        !_vm.hasSlot
          ? _c("textarea", {
              attrs: { rows: "5", maxlength: _vm.maxlength },
              domProps: { value: _vm.value },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", $event.target.value)
                },
              },
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }