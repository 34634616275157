var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        height: "24px",
        viewBox: "0 0 24 24",
        width: "24px",
        fill: "#000000",
      },
    },
    [
      _c("path", { attrs: { d: "M24 24H0V0h24v24z", fill: "none" } }),
      _c("circle", { attrs: { cx: "12", cy: "12", r: "8" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }