var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: _vm.$route.fullPath, staticClass: "plot-edit-page" },
    [
      [
        _c("plot-edit", {
          attrs: {
            "novel-id": _vm.novelId,
            plots: _vm.plot,
            plotId: _vm.plotId,
          },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }