var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        height: "24px",
        viewBox: "0 0 24 24",
        width: "24px",
      },
    },
    [
      _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } }),
      _c("path", {
        attrs: {
          d:
            "M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }