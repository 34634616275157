import { render, staticRenderFns } from "./ManuscriptEditor.vue?vue&type=template&id=4333b011&scoped=true"
import script from "./ManuscriptEditor.vue?vue&type=script&lang=ts"
export * from "./ManuscriptEditor.vue?vue&type=script&lang=ts"
import style0 from "./ManuscriptEditor.vue?vue&type=style&index=0&id=4333b011&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4333b011",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/satoru/dev/Nola/nola-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4333b011')) {
      api.createRecord('4333b011', component.options)
    } else {
      api.reload('4333b011', component.options)
    }
    module.hot.accept("./ManuscriptEditor.vue?vue&type=template&id=4333b011&scoped=true", function () {
      api.rerender('4333b011', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/ManuscriptEditor.vue"
export default component.exports