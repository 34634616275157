var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("label", { attrs: { for: `file_uploader_${_vm.id}` } }, [
    _vm._v(" ローカルから選択 "),
    _c("input", {
      staticClass: "input",
      attrs: { id: `file_uploader_${_vm.id}`, type: "file" },
      on: { change: _vm.onFileChange },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }