var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "li",
    {
      class: {
        "list-item": true,
        active: _vm.isActive,
        preview: _vm.previewMode,
      },
      on: { click: _vm.click },
    },
    [
      _c("character-info", { attrs: { character: _vm.selectedCharacterA } }),
      _c("correlation-arrows", {
        attrs: {
          showPlaceholder: _vm.showPlaceholder,
          relationshipAtoB: _vm.relationshipAtoB,
          relationshipBtoA: _vm.relationshipBtoA,
        },
      }),
      _c("character-info", { attrs: { character: _vm.selectedCharacterB } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }