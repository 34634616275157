var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: { click: _vm.onClickOutSide },
    },
    [
      _c("div", { staticClass: "dialog-box" }, [
        _vm._m(0),
        _c("div", { staticClass: "section dialog-body" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("span", { staticClass: "large" }, [_vm._v("自動校正機能 ")]),
              _c("toggle-button", {
                attrs: {
                  disabled: _vm.isUnsupportedDevice,
                  value: _vm.enable,
                  labels: true,
                },
                on: { change: _vm.onChangeState },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "large" }, [_vm._v("校正ルールの選択")]),
          _c(
            "div",
            {
              staticClass: "detail",
              attrs: {
                "data-disabled": !_vm.enable || _vm.isUnsupportedDevice,
              },
            },
            [
              _vm._l(_vm.ruleList, function (rule, index) {
                return [
                  _c(
                    "div",
                    { key: rule.key.toString(), staticClass: "setting-item" },
                    [
                      _c("label", [
                        _c("input", {
                          attrs: {
                            type: "checkbox",
                            "data-index": index,
                            "data-value": rule.value
                              ? JSON.stringify(rule.value)
                              : null,
                            disabled: !_vm.enable,
                          },
                          domProps: { checked: _vm.isChecked(rule) },
                          on: { change: _vm.onChange },
                        }),
                        _vm._v("   " + _vm._s(rule.text) + " "),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
          _vm.isUnsupportedDevice
            ? _c("div", { staticClass: "unsupported-message" }, [
                _vm._v("校正機能は現在 PC のみでご利用いただけます。"),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "section button-area" }, [
          _c(
            "button",
            {
              staticClass: "button cancel",
              on: { click: _vm.onNegativeClick },
            },
            [_vm._v("キャンセル")]
          ),
          _c(
            "button",
            {
              staticClass: "button submit",
              attrs: { disabled: !_vm.modified },
              on: { click: _vm.onSave },
            },
            [_vm._v("保存する")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "section dialog-header" }, [
      _c("h1", [_vm._v("校正機能の設定")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }