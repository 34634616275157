var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "shared-link-list" }, [
    _c("span", { staticClass: "title" }, [_vm._v("共有リンク一覧")]),
    _c(
      "div",
      { staticClass: "list" },
      [
        _vm.sharedLinkList.length
          ? _c(
              "transition-group",
              { attrs: { name: "tran" } },
              _vm._l(_vm.sharedLinkList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.sharedLinkId,
                    staticClass: "item",
                    on: {
                      click: function ($event) {
                        return _vm.select(item.sharedLinkId)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "item-title ellipsis" }, [
                      _vm._v(" " + _vm._s(item.name || "NO TITLE") + " "),
                    ]),
                    _vm.isIncludesUnreadComment(item.sharedLinkId)
                      ? _c("dot-icon", { staticClass: "notification" })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            )
          : _c("div", { staticClass: "description" }, [
              _c("div", [_vm._v("共有リンクはありません")]),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }