
import Vue from "vue";
import CharacterForm from "@/components/organisms/CharacterForm.vue";
import ImageSelector from "@/components/molecules/ImageSelector.vue";
import { submitImage } from "@/lib/image-save";
import { Character, CreateCharacter, CopyCharacter } from "@/lib/models";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import { handleShortcutKeys, TriggerKey } from "@/lib/utils/keyboardShortcuts";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { CharacterForm, ImageSelector },
  props: {
    novelId: String,
    characterFolderId: String,
  },
  data() {
    return {
      model: {
        name: "",
        detail: [],
        additionalColumn: [],
      },
      image: null,
      saving: false,
      characterIdToCopy: this.$route.params.characterIdToCopy,
      unbindShortcutKeys: null,
    };
  },
  created() {
    if (this.characterIdToCopy) {
      if (this.character) {
        const {
          character: { name, detail, additionalColumn, image },
        } = this;

        this.image = image;
        this.model = {
          name: `${name}のコピー`,
          detail,
          additionalColumn,
        };
      }
    }

    // 保存ボタンのショートカットキーの有効化と解除関数の取得
    this.unbindShortcutKeys = handleShortcutKeys([
      { trigger: TriggerKey.Meta, keys: ["s"], callback: this.submit },
      { trigger: TriggerKey.Ctrl, keys: ["s"], callback: this.submit },
    ]);
  },
  beforeDestroy() {
    // コンポーネントが破棄される前に保存ボタンのショートカットキーを解除
    if (this.unbindShortcutKeys) this.unbindShortcutKeys();
  },
  computed: {
    character() {
      return cloneDeep(this.$store.getters["characterModule/character"](this.characterIdToCopy));
    },
    imagePath() {
      const { novelId, image } = this;
      if (!image) return null;
      if (image.startsWith("file:")) {
        return `file:novels/${novelId}/characters/${this.characterIdToCopy}.jpg`;
      }
      if (image.startsWith("preset:")) {
        return image;
      }
      return null;
    },
  },
  methods: {
    change() {
      this.setModified(true);
    },
    async submit() {
      if (!this.model.name || this.saving) {
        return;
      }
      try {
        this.saving = true;

        const { novelId, characterFolderId, model, image } = this;
        const { additionalColumn } = model;
        model.additionalColumn = additionalColumn.filter((item) => item.label || item.content);

        let characterId: string;
        // characterIdToCopyが存在する場合は、そのIDを持つ既存のキャラクターを複製する。
        if (this.characterIdToCopy) {
          characterId = uuidv4();

          const copyCharacterInput: CopyCharacter = {
            novelId,
            characterId,
            image,
            ...model,
            copySource: `novels/${novelId}/characters/${this.characterIdToCopy}.jpg`,
            destinationKey: `novels/${novelId}/characters/${characterId}.jpg`,
          };
          await this.$store.dispatch("characterModule/copyCharacter", copyCharacterInput);
          this.$ga.event("novel", "copy-character", "登場人物複製", 0);
        } else {
          // characterIdToCopyが存在しない場合は、新しいキャラクターを作成する。
          const createCharacterInput: CreateCharacter = {
            novelId,
            characterFolderId,
            image,
            ...model,
          };
          const { characterId: newCharacterId } = await this.$store.dispatch(
            "characterModule/createCharacter",
            createCharacterInput
          );
          characterId = newCharacterId;

          await submitImage(`novels/${novelId}/characters/${characterId}.jpg`);
          this.$ga.event("novel", "create-character", "登場人物新規作成", 0);
        }

        this.setModified(false);
        this.$router.push({
          name: "characters",
          params: { novelId, characterId },
        });

        this.$notify({
          title: "保存しました",
          type: "success",
        });
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "保存に失敗しました",
          type: "error",
        });
      } finally {
        this.saving = false;
      }
    },
  },
});

interface Props {
  novelId: string;
  characterFolderId: string;
}

interface Data {
  model: {
    name: string;
    detail: any[];
    additionalColumn: any[];
  };
  image: string | null;
  saving: boolean;
  characterIdToCopy?: string;
  unbindShortcutKeys: (() => void) | null;
}

interface Computed {
  character: Character;
  imagePath: string | null;
}

interface Methods {
  change(): void;
  submit(): Promise<void>;
}
