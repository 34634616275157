var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "title-container",
      class: { multiEditor: !_vm.isNotMultiEditorPath },
    },
    [
      _c("input", {
        staticClass: "title",
        attrs: {
          type: "text",
          placeholder: "タイトル",
          maxlength: "100",
          "data-theme": _vm.theme,
        },
        domProps: { value: _vm.title },
        on: {
          input: _vm.onChangeTitle,
          compositionstart: _vm.compositionStart,
          compositionend: _vm.compositionEnd,
        },
      }),
      !_vm.isMobileDevice && _vm.isNotMultiEditorPath
        ? _c(
            "div",
            { staticClass: "header-container" },
            [
              _c("search-icon", {
                staticClass: "icon",
                attrs: {
                  fillColor: _vm.enabledSearchReplace
                    ? _vm.activeColor
                    : _vm.defaultColor,
                },
                on: { click: _vm.toggleEnabledSearchReplace },
              }),
              _c("history-icon", {
                staticClass: "icon",
                attrs: {
                  fillColor: _vm.isVersionsDialogOpen
                    ? _vm.activeColor
                    : _vm.defaultColor,
                },
                on: { click: _vm.showVersions },
              }),
              _c(
                "div",
                {
                  staticClass: "icon",
                  on: { click: _vm.showPublishSharedLink },
                },
                [
                  _c("link-icon", {
                    attrs: {
                      isActive: _vm.isShowPublishSharedLink,
                      activeColor: _vm.activeColor,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "icon", on: { click: _vm.onClickCommentIcon } },
                [
                  _c("comment-icon", {
                    attrs: {
                      fillColor: _vm.defaultColor,
                      isUnread: _vm.hasUnreadComment,
                    },
                  }),
                ],
                1
              ),
              _c(
                "button",
                { staticClass: "button post", on: { click: _vm.post } },
                [_vm._v("投稿する")]
              ),
              _c(
                "button",
                {
                  staticClass: "button save",
                  attrs: { disabled: !_vm.changed },
                  on: { click: _vm.save },
                },
                [_vm._v("保存する")]
              ),
              _c("hamburger-menu", {
                staticClass: "hamburger-menu",
                attrs: {
                  items: _vm.hamburgerMenuItems,
                  togglableItems: _vm.hamburgerMenuTogglableItems,
                  styleObject: { backgroundColor: "#EDEDED" },
                  position: "bottom",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.enabledSearchReplace && _vm.isNotMultiEditorPath
        ? _c("SearchReplaceDialog", { staticClass: "dialog" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }