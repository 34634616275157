var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "title" }, [_vm._v("イメージ画像")]),
    _c("div", { staticClass: "image-selector" }, [
      _c(
        "div",
        { staticClass: "preview-cell" },
        [
          _vm.type !== "local"
            ? _c("image-view", {
                staticClass: "preview",
                attrs: {
                  src: _vm.displayingImage,
                  placeholder: _vm.placeholder,
                },
              })
            : _vm._e(),
          _c("canvas", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type === "local",
                expression: "type === 'local'",
              },
            ],
            ref: "localImgCanvas",
            class: _vm.type,
            attrs: { "data-clip": "", width: "1000", height: "1000" },
            on: {
              mousedown: _vm.mouseDownEvent,
              mouseup: _vm.mouseUpEvent,
              mousemove: _vm.mouseMoveEvent,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button-local" },
        [
          _c("local-image-selector", {
            staticClass: "button l",
            attrs: { prefix: "" },
            on: { "update:file": _vm.loadLocalImage },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "button-preset" }, [
        _c(
          "button",
          { staticClass: "button r", on: { click: _vm.openPresetDialog } },
          [_vm._v("プリセットから選択")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }