var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("sp-tab", {
        attrs: { active: "worldViews", hidden: !!_vm.worldViewId },
      }),
      _vm.worldViews
        ? _c(
            "list-template",
            {
              attrs: {
                active: !!_vm.worldViewId,
                selected: _vm.selectedWorldView,
              },
            },
            [
              _vm.worldViewFolders && _vm.isShowFolderList
                ? _c(
                    "div",
                    { attrs: { slot: "side" }, slot: "side" },
                    [
                      _c("world-view-folder-list", {
                        attrs: {
                          novelId: _vm.novelId,
                          items: _vm.worldViewFolders,
                          worldViewsCount: _vm.worldViewsCount,
                          isInsert: _vm.isFolderInsertMode,
                          selectedWorldView: _vm.targetWorldView,
                        },
                        on: {
                          selectedFolder: _vm.selectFolder,
                          clickCompleteSetFolder: function ($event) {
                            return _vm.finishFolderSelect()
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { attrs: { slot: "side" }, slot: "side" },
                    [
                      _c("world-view-list", {
                        attrs: {
                          slot: "side",
                          novelId: _vm.novelId,
                          worldViews: _vm.worldViews,
                          selectedId:
                            _vm.selectedWorldView &&
                            _vm.selectedWorldView.worldViewId,
                          folder: _vm.worldViewFolder,
                          allFolders: _vm.worldViewFolders,
                        },
                        on: {
                          backToFolderList: function ($event) {
                            return _vm.showFolderList()
                          },
                          clickSetFolder: _vm.setFolder,
                        },
                        slot: "side",
                      }),
                    ],
                    1
                  ),
              _c(
                "back-to-list",
                {
                  attrs: {
                    slot: "back-to-list",
                    to: {
                      name: "worldViews",
                      params: { novelId: _vm.novelId },
                    },
                  },
                  slot: "back-to-list",
                },
                [_vm._v("＜一覧に戻る")]
              ),
              _vm.selectedWorldView
                ? _c("world-view-detail", {
                    attrs: {
                      slot: "main",
                      novelId: _vm.novelId,
                      worldView: _vm.selectedWorldView,
                    },
                    slot: "main",
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }