var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "correlation-content" },
    [
      _c("input-text", {
        staticClass: "title",
        attrs: { label: "相関関係のタイトル", maxlength: 100 },
        on: { input: _vm.change },
        model: {
          value: _vm.name,
          callback: function ($$v) {
            _vm.name = $$v
          },
          expression: "name",
        },
      }),
      _c("correlation-diagram", {
        attrs: {
          novelId: _vm.novelId,
          diagram: _vm.diagram,
          characterItems: _vm.characterItems,
          selectedCorrelationList: _vm.filterCorrelationList(),
        },
        on: {
          clickedShowSelectCorrelation: function ($event) {
            _vm.isSelecting = !_vm.isSelecting
          },
          move: _vm.moveCharacter,
        },
      }),
      _vm.isSelecting
        ? _c("div", { staticClass: "select-correlation" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "div",
                  {
                    staticClass: "head",
                    on: {
                      click: function ($event) {
                        _vm.isSelecting = false
                      },
                    },
                  },
                  [_vm._v("× ウィンドウを閉じる")]
                ),
                _c("div", { staticClass: "description" }, [
                  _vm._v("相関図に追加する相関関係を選択してください"),
                ]),
                _vm._l(_vm.correlationList, function (item) {
                  return _c(
                    "div",
                    { key: item.correlationId, staticClass: "list-item" },
                    [
                      _c("correlation-list-item", {
                        key: item.correlationId,
                        attrs: {
                          previewMode: "",
                          characterA: _vm.getCharacterData(item.characterA),
                          characterB: _vm.getCharacterData(item.characterB),
                          relationshipAtoB: item.relationshipAtoB,
                          relationshipBtoA: item.relationshipBtoA,
                        },
                      }),
                      _c("div", { staticClass: "select-button" }, [
                        _c(
                          "button",
                          {
                            class: {
                              button: true,
                              selected: _vm.isSelectedCorrelation(
                                item.correlationId
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.selectCorrelation(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isSelectedCorrelation(item.correlationId)
                                    ? "選択中"
                                    : "未選択"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "bottom" }, [
        _c(
          "button",
          {
            staticClass: "button submit",
            attrs: { disabled: !_vm.changed },
            on: { click: _vm.save },
          },
          [_vm._v("保存する")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }